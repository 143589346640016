import { Box, Button, Stack } from "@mui/material";
import { client } from "../../util";
import type { CustomerCreate, CustomerSearchOutput, Customer, PaymentTermsCreateInput, PaymentTermsUpdateInput, TaxCodeCreateInput, TaxCodeUpdateInput, VendorTermsCreateInput, VendorCreate, Vendor, ItemCreate, Item, ItemCategoriesUpdate } from "orderflow-lambdas";
import { useDispatch, useSelector } from "../../state";
import { pushToast } from "../../state/toastSlice";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../../components/modals/constants/errors";

export default function Dashboard() {

  const { team } = useSelector(({ userSlice }) => userSlice);

  const dispatch = useDispatch();

  if (team === "init" || team === "loading") {
    return <div>Loading...</div>;
  }

  const getItemCategories = async () => {
    try {
      const categories = await client.getCategories.query();
      console.log("categories", categories);
      dispatch(pushToast({ message: "categories queried", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to fetch categories", type: "error" }));
    }
  }
  const updateItemCategories = async () => {
    try {
      const newCategories: ItemCategoriesUpdate = {
        categories: ["new category"],
      }
      const categories = await client.updateCategories.mutate(newCategories);
      console.log("categories", categories);
      dispatch(pushToast({ message: "categories updated", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to update categories", type: "error" }));
    }
  }

  const searchItemByVendorName = async () => {
    try {
      const results = await client.searchItems.query({
        searchBy: "vendorName",
        query: "google",
        size: 10,
        page: 0,
      });
      console.log("searched items by vendor name", results);
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to search items by vendor name", type: "error" }));
    }
  }
  const setDefaultTaxCode = async () => {
    try {
      await client.setDefaultTaxCode.mutate("f68c89bc-6205-40fb-ac15-d522b8c648e6");
      dispatch(pushToast({ message: "default tax code set", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to set default tax code", type: "error" }));
    }
  }

  const deletePaymentTerm = async () => {
    try {
      await client.deletePaymentTerm.mutate("849e6885-e05f-4c91-ae3f-81e9a4f8db5d");
      dispatch(pushToast({ message: "payment term deleted", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to delete Payment terms", type: "error" }));
    }
  }
  const updatePaymentTermById = async () => {
    try {
      const payload: PaymentTermsUpdateInput = {
        PaymentTermId: "849e6885-e05f-4c91-ae3f-81e9a4f8db5d",
        name: "Net 31 updated",
        days: 31,
        weight: 1,
      }
      const paymentTerms = await client.updatePaymentTerm.mutate(payload);
      console.log("paymentTerms", paymentTerms);
      dispatch(pushToast({ message: "payment term updated", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to update Payment terms", type: "error" }));
    }
  }
  const getPaymentTermById = async () => {
    try {
      const paymentTerms = await client.getPaymentTermById.query("849e6885-e05f-4c91-ae3f-81e9a4f8db5d");
      console.log("paymentTerms", paymentTerms);
      dispatch(pushToast({ message: "payment term queried", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to fetch Payment terms", type: "error" }));
    }
  }
  const getAllPaymentTerms = async () => {
    try {
      const paymentTerms = await client.allPaymentTerms.query();
      console.log("paymentTerms", paymentTerms);
      dispatch(pushToast({ message: "payment term queried", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to fetch Payment terms", type: "error" }));
    }
  }
  const createPaymentTerm = async () => {
    try {
      const payload: PaymentTermsCreateInput = {
        name: "Net 30",
        days: 30,
        weight: 1,
      }
      const paymentTerm = await client.createPaymentTerm.mutate(payload);
      dispatch(pushToast({ message: "payment term created", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to create Payment term", type: "error" }));
    }
  }
  const createVendorTerm = async () => {
    try {
      const payload: VendorTermsCreateInput = {
        name: "Net 30",
        days: 30,
        weight: 1,
      }
      const vendorTerm = await client.createVendorTerm.mutate(payload);
      dispatch(pushToast({ message: `vendor term created with id: ${vendorTerm.VendorTermId}`, type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to create Vendor term", type: "error" }));
    }
  }
  const deleteTaxCode = async () => {
    try {
      await client.deleteTaxCode.mutate("ef34d99e-4c71-4d33-bb5d-deb412c0e20d");
      dispatch(pushToast({ message: "tax code deleted", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to delete tax code", type: "error" }));
    }
  }
  const updateTaxCodeById = async () => {
    try {
      const payload: TaxCodeUpdateInput = {
        TaxCodeId: "ef34d99e-4c71-4d33-bb5d-deb412c0e20d",
        codeName: "LA Updated",
        description: "updated",
        rate: 1,
      }
      const taxCode = await client.updateTaxCode.mutate(payload);
      console.log("paymentTerms", taxCode);
      dispatch(pushToast({ message: "tax code updated", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to update tax code", type: "error" }));
    }
  }
  const getTaxCodeById = async () => {
    try {
      const taxCode = await client.getTaxCodeById.query("ef34d99e-4c71-4d33-bb5d-deb412c0e20d");
      console.log("tax code", taxCode);
      dispatch(pushToast({ message: "tax code queried", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to fetch tax code", type: "error" }));
    }
  }
  const getAllTaxCodes = async () => {
    try {
      const taxCodes = await client.allTaxCodes.query();
      console.log("paymentTerms", taxCodes);
      dispatch(pushToast({ message: "tax codes queried", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to fetch Tax Codes", type: "error" }));
    }
  }
  const createTaxCode = async () => {
    try {
      const payload: TaxCodeCreateInput = {
        codeName: "LA",
        rate: 0.1,
        description: "the sales tax code for la proper",
      }
      const taxCode = await client.createTaxCode.mutate(payload);
      dispatch(pushToast({ message: "tax code created", type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to create tax code", type: "error" }));
    }
  }

  const createItem = async () => {
    
    const payload: ItemCreate = {
      details: {
        primaryVendor: "google",
        description: "Test Item",
        taxable: false,
        commissionable: false,
        unit: "grams",
        minimumRequirements: 0,
        assemblyItem: false,
        customerPrice: 1.57,
        vendorItemNumber: "123456",
      },
      priceBreaks: [],
      assembly: [],
    }

    try {
      console.log("payload", payload);
      const newItem: Item = await client.createItem.mutate(payload);
      console.log("newItem", newItem);
      dispatch(pushToast({ message: `Item created with id: ${newItem.ItemId}`, type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to create Item", type: "error" }));
    }
  };
  const createVendor = async () => {
    
    const payload: VendorCreate = {
      notes: "some notes",
      showNotes: true,
      "details": {
        "companyName": "Netflix",
        "email": "test@example.us",
        "phone": "6019521325",
        "address": "1600 Amphitheatre Parkway",
        "city": "Mountain View",
        "state": "CA",
        "country": "US",
        "zipCode": "94043",
        "preferredCommunication": "Email",
        "website": "www.google.com",
        "other": "Test",
        accountNumber: "123456",
      },
      "payment": {
        "currentBalance": 25000,
        "rebates": false,
        "volume": 543000,
        "percentage": 0.5,
        "minimum": 10000,
        "lastOrderDate": 1724223600
      },
      "receivable": {
        "companyName": "Google",
        "email": "test@example.us",
        "phone": "6019521325",
        "address": "1600 Amphitheatre Parkway",
        "city": "Mountain View",
        "state": "CA",
        "country": "US",
        "zipCode": "94043",
        "preferredCommunication": "Email",
        "contactName": "Gurgen"
      },
      "shipping": {
        "companyName": "Google",
        "email": "test@example.us",
        "phone": "6019521325",
        "address": "1600 Amphitheatre Parkway",
        "city": "Mountain View",
        "state": "CA",
        "country": "US",
        "zipCode": "94043",
        "preferredCommunication": "Email",
        "contactName": "Gurgen"
      },
      "contacts": [
        {
          "contactName": "Juan Francisco García Flores",
          "address": "C. Montes Urales 445",
          "email": "ejemplo@ejemplo.mx",
          "city": "Ciudad de México",
          "phone": "5553428400",
          "zipCode": "11000",
          "position": "CEO",
          "state": "AR"
        },
        {
          "contactName": "João Souza Silva",
          "address": "Av. dos Andradas, 3000",
          "email": "teste@exemplo.us",
          "city": "Belo Horizonte",
          "phone": "3121286800",
          "zipCode": "30260-070",
          "position": "CEO",
          "state": "MS"
        },
        {
          "contactName": "Gottfried Wilhelm Leibniz",
          "address": "Erika-Mann-Straße 33",
          "email": "test@beispiel.de",
          "city": "München",
          "phone": "030303986300",
          "zipCode": "80636",
          "position": "Developer"
        }
      ]
    }

    try {
      console.log("payload", payload);
      const newVendor: Vendor = await client.createVendor.mutate(payload);
      console.log("newVendor", newVendor);
      dispatch(pushToast({ message: `Vendor created with id: ${newVendor.VendorId}`, type: "success" }));
    } catch (error) {
      console.error("error", error);
      dispatch(pushToast({ message: "unable to create Vendor", type: "error" }));
    }
  };
  const createCustomer = async () => {
    
    const payload: CustomerCreate = {
      "details": {
        "companyName": "Netflix",
        "email": "test@example.us",
        "phone": "6019521325",
        "address": "1600 Amphitheatre Parkway",
        "city": "Mountain View",
        "state": "CA",
        "country": "US",
        "zipCode": "94043",
        "preferredCommunication": "Email",
        "website": "www.google.com",
        "other": "Test"
      },
      "payment": {
        "currentBalance": 25000,
        "taxable": false,
        "creditLimit": 543000,
        "exemptCode": "TEST Exempt Code",
        "lastOrderDate": 1724223600
      },
      "billing": {
        "companyName": "Google",
        "email": "test@example.us",
        "phone": "6019521325",
        "address": "1600 Amphitheatre Parkway",
        "city": "Mountain View",
        "state": "CA",
        "country": "US",
        "zipCode": "94043",
        "preferredCommunication": "Email",
        "contactName": "Gurgen"
      },
      "delivery": [{
        "companyName": "Google",
        "email": "test@example.us",
        "phone": "6019521325",
        "address": "1600 Amphitheatre Parkway",
        "city": "Mountain View",
        "state": "CA",
        "country": "US",
        "zipCode": "94043",
        "preferredCommunication": "Email",
        "contactName": "Gurgen"
      }],
      "notes": "some notes",
      "contacts": [
        {
          "contactName": "Juan Francisco García Flores",
          "address": "C. Montes Urales 445",
          "email": "ejemplo@ejemplo.mx",
          "city": "Ciudad de México",
          "phone": "5553428400",
          "zipCode": "11000",
          "position": "CEO",
          "state": "AR"
        },
        {
          "contactName": "João Souza Silva",
          "address": "Av. dos Andradas, 3000",
          "email": "teste@exemplo.us",
          "city": "Belo Horizonte",
          "phone": "3121286800",
          "zipCode": "30260-070",
          "position": "CEO",
          "state": "MS"
        },
        {
          "contactName": "Gottfried Wilhelm Leibniz",
          "address": "Erika-Mann-Straße 33",
          "email": "test@beispiel.de",
          "city": "München",
          "phone": "030303986300",
          "zipCode": "80636",
          "position": "Developer"
        }
      ]
    }
    // const payload: CustomerCreate = {
    //   details: {
    //     companyName: "Test Company",
    //     }
    // };
    console.log("payload", payload);
    const newCustomer: Customer = await client.createCustomer.mutate(payload);
    console.log("newCustomer", newCustomer);
  };
  const getCustomer = async () => {
    const customer: Customer = await client.customerById.query("134fb326-4e28-4767-ad7a-f57d7f23aaaf");
    console.log("newCustomer", customer);
  };

  const searchCustomers = async () => {
    const customers: CustomerSearchOutput = await client.searchCustomers.query({
      searchBy: "companyName",
      query: "Google",
      size: 10,
      page: 0,
    });
    console.log("customers from search", customers);
  };

  const deleteCustomer = async () => {
    try {
      
      const idToDelete = "6a0a999d-f755-4e6f-ac5c-020f27985b1e";
      await client.deleteCustomer.mutate(idToDelete);
      dispatch(pushToast({ message: `Customer removed`, type: "success" }));
    } catch (error) {
      console.error("error", error);
      if(error instanceof TRPCClientError) {
        dispatch(pushToast({ message: error.message, type: "error" }));
      } else {
        dispatch(pushToast({ message: serverError, type: "error" }));
      }
      
    }
  };

  const updateCustomer = async () => {
    try {
      
      const idToUpdate = "5c3cb0a0-d5f4-4417-a864-434e2dc15c8f";
      const customer = await client.customerById.query(idToUpdate);
      const customerPayload  = {
        ...customer,
        details: {
          ...customer.details,
          companyName: `Updated ${customer.details.companyName}`
        }
      }
      const updatedCustomer = await client.updateCustomer.mutate(customerPayload);
      dispatch(pushToast({ message: `Customer name updated from ${customer.details.companyName} to ${updatedCustomer.details.companyName}`, type: "success" }));
    } catch (error) {
      console.error("error", error);
      if(error instanceof TRPCClientError) {
        dispatch(pushToast({ message: error.message, type: "error" }));
      } else {
        dispatch(pushToast({ message: serverError, type: "error" }));
      }
      
    }
  };

  const getMyTeam = async () => {
    try {
      
      const myTeam = await client.getMyTeam.query();
      console.log("myTeam", myTeam);
      dispatch(pushToast({ message: `Team retrieved`, type: "success" }));
    } catch (error) {
      console.error("error", error);
      if(error instanceof TRPCClientError) {
        dispatch(pushToast({ message: error.message, type: "error" }));
      } else {
        dispatch(pushToast({ message: serverError, type: "error" }));
      }
      
    }
  };
  

  return (
    <>
      <h1 className="text-blue-600">Dashboard Page</h1>
      <Stack flexDirection="row" padding={2} columnGap={2} alignItems="center">
        <h3>
          Item Categories
        </h3>
      <Button variant="contained" color="primary" onClick={updateItemCategories}>
       Update Item Categories
      </Button>
      <Button variant="contained" color="primary" onClick={getItemCategories}>
       Get Item Categories
      </Button>
      </Stack>
      <Stack flexDirection="row" padding={2} columnGap={2} alignItems="center">
        <h3>
          Tax Codes
        </h3>
      <Button variant="contained" color="primary" onClick={createTaxCode}>
        Create Tax Code
      </Button>
      <Button variant="contained" color="primary" onClick={getAllTaxCodes}>
        Get All Tax Codes
      </Button>
      <Button variant="contained" color="primary" onClick={getTaxCodeById}>
        Get Tax Code By Id
      </Button>
      <Button variant="contained" color="primary" onClick={updateTaxCodeById}>
        Update Tax Code
      </Button>
      <Button variant="contained" color="primary" onClick={deleteTaxCode}>
        Delete Tax Code
      </Button>
      <Button variant="contained" color="primary" onClick={setDefaultTaxCode}>
        Set Default Tax Code
      </Button>
      </Stack>
      <Stack flexDirection="row" padding={2} columnGap={2} alignItems="center">
        <h3>
          Vendor Terms
        </h3>
      <Button variant="contained" color="primary" onClick={createVendorTerm}>
        Create Vendor Term
      </Button>
      </Stack>
      <Stack flexDirection="row" padding={2} columnGap={2} alignItems="center">
        <h3>
          Vendors
        </h3>
        <Button variant="contained" color="primary" onClick={createVendor}>
        Create Vendor
      </Button>
      </Stack>
      <Stack flexDirection="row" padding={2} columnGap={2} alignItems="center">
        <h3>
          Items
        </h3>
        <Button variant="contained" color="primary" onClick={createItem}>
        Create Item
      </Button>
      <Button variant="contained" color="primary" onClick={searchItemByVendorName}>
       Search Items by Vendor Name
      </Button>
      </Stack>
      <Button variant="contained" color="primary" onClick={createCustomer}>
        Create Customer
      </Button>
      <Button variant="contained" color="primary" onClick={getCustomer}>
        Get Customer
      </Button>
      <Button variant="contained" color="primary" onClick={searchCustomers}>
        Search Customer
      </Button>
      <Button variant="contained" color="primary" onClick={deleteCustomer}>
        Delete Customer
      </Button>
      <Button variant="contained" color="primary" onClick={updateCustomer}>
        Update Customer
      </Button>
      <Button variant="contained" color="primary" onClick={getMyTeam}>
        Get My Team
      </Button>
      <Button variant="contained" color="primary" onClick={createPaymentTerm}>
        Create Payment Term
      </Button>
      <Button variant="contained" color="primary" onClick={getAllPaymentTerms}>
        Get All Payment Term
      </Button>
      <Button variant="contained" color="primary" onClick={getPaymentTermById}>
        Get Payment Term By Id
      </Button>
      <Button variant="contained" color="primary" onClick={updatePaymentTermById}>
        Update Payment Term 
      </Button>
      <Button variant="contained" color="primary" onClick={deletePaymentTerm}>
        Delete Payment Term 
      </Button>
    </>
  );
}
