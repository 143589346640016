import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Customer, CustomerSearchOutput, CustomerSearchInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"
type SearchBy = CustomerSearchInput['searchBy']

export interface CustomerSearchState {
  searchInput: string
  openCustomerSearchModal: boolean
  resp?: CustomerSearchOutput
  searchBy: SearchBy
  loading: boolean,
  searchLoading: boolean,
  selectedCustomers: Customer[];
  multiSelect: boolean,
  onImport?: (selectedCustomers: Customer[]) => void
  onCancel?: () => void
}

// Initialize state
const initialState: CustomerSearchState = {
  searchInput: '',
  openCustomerSearchModal: false,
  resp: undefined,
  searchBy: 'companyName',
  loading: false,
  searchLoading: false,
  multiSelect: false,
  selectedCustomers: [],
  onImport: undefined,
  onCancel: undefined
}

export const CustomerSearchModalSlice = createSlice({
  name: "CustomerSearch",
  initialState,
  reducers: {
    openSearchCustomerModal: (state, action: PayloadAction<{ onImport?: (selectedCustomers: Customer[]) => void, onCancel?: () => void, multiSelect?: boolean }>) => ({
      ...initialState,
      openCustomerSearchModal: true,
      onImport: action.payload.onImport,
      onCancel: action.payload.onCancel,
      multiSelect: action.payload.multiSelect || state.multiSelect
    }),
    closeModal: (state) => ({

      ...state,
      openCustomerSearchModal: false
    }),
    setSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      searchInput: action.payload
    }),
    setSearchBy: (state, action: PayloadAction<SearchBy>) => ({
      ...state,
      searchBy: action.payload
    }),
    setResults: (state, action: PayloadAction<CustomerSearchOutput | undefined>) => ({
      ...state,
      resp: action.payload
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload
    }),
    setSearchLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      searchLoading: action.payload
    }),
    setSelectedCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.selectedCustomers = action.payload;
    },
  },
})

export const {
  openSearchCustomerModal,
  closeModal,
  setSearchInput,
  setSearchBy,
  setResults,
  setLoading,
  setSearchLoading,
  setSelectedCustomers,
} = CustomerSearchModalSlice.actions
export default CustomerSearchModalSlice.reducer

export const searchCustomers = (searchInput: CustomerSearchInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setSearchLoading(true))
    const resp = await ApiClient.searchCustomers.query(searchInput)
    dispatch(setResults(resp))
  } catch (error: unknown) {
    dispatch(setResults(undefined))
  } finally {
    dispatch(setSearchLoading(false))
  }
}
