import { Box, Typography, TextField, MenuItem, Divider } from "@mui/material";
import type { OrderflowSettings } from "orderflow-lambdas";
import { textFieldProps, textFieldSelectProps } from "../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../util/fornik-helpers";
import { gridPaymentItemProps } from "../customers/createAndEdit/constants";
import usaStates from "../../../static/usaStates.json";
import { useTranslation } from "react-i18next";

export default function SettingsTab({
    settingsFormik,
}: {
    settingsFormik: FormikProps<OrderflowSettings>;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tMSS } = useTranslation([], { keyPrefix: "team.Modals.Settings.SettingsTab" })

    return (
        <>
            <div className="grid grid-flow-col grid-rows-6 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Company Name")} name="general.companyName" required
                        error={Boolean(getFormikField(settingsFormik, "general.companyName").error)}
                        helperText={getFormikField(settingsFormik, "general.companyName").error}
                        value={getFormikField(settingsFormik, "general.companyName").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="general.address"
                        error={Boolean(getFormikField(settingsFormik, "general.address").error)}
                        helperText={getFormikField(settingsFormik, "general.address").error}
                        value={getFormikField(settingsFormik, "general.address").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="general.city"
                        error={Boolean(getFormikField(settingsFormik, "general.city").error)}
                        helperText={getFormikField(settingsFormik, "general.city").error}
                        value={getFormikField(settingsFormik, "general.city").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="general.state"
                        error={Boolean(getFormikField(settingsFormik, "general.state").error)}
                        helperText={getFormikField(settingsFormik, "general.state").error}
                        value={getFormikField(settingsFormik, "general.state").value} onChange={settingsFormik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="general.zip"
                        error={Boolean(getFormikField(settingsFormik, "general.zip").error)}
                        helperText={getFormikField(settingsFormik, "general.zip").error}
                        value={getFormikField(settingsFormik, "general.zip").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="general.country"
                        error={Boolean(getFormikField(settingsFormik, 'general.country').error)}
                        helperText={getFormikField(settingsFormik, 'general.country').error}
                        value={getFormikField(settingsFormik, 'general.country').value} onChange={settingsFormik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="general.phone"
                        error={Boolean(getFormikField(settingsFormik, "general.phone").error)}
                        helperText={getFormikField(settingsFormik, "general.phone").error}
                        value={getFormikField(settingsFormik, "general.phone").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSS("EID No.")} name="general.employerId" type="number"
                        error={Boolean(getFormikField(settingsFormik, "general.employerId").error)}
                        helperText={getFormikField(settingsFormik, "general.employerId").error}
                        value={getFormikField(settingsFormik, "general.employerId").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField
                        variant="filled"
                        {...textFieldProps}
                        label={tMSS("Additional ID No. 1")}
                        name="general.additionalIds[0]"
                        type="number"
                        value={settingsFormik.values.general?.additionalIds?.[0] || ''}
                        onChange={(event) => settingsFormik.setFieldValue("general.additionalIds[0]", event.target.value)}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField
                        variant="filled"
                        {...textFieldProps}
                        label={tMSS("Additional ID No. 2")}
                        name="general.additionalIds[1]"
                        type="number"
                        value={settingsFormik.values.general?.additionalIds?.[1] || ''}
                        onChange={(event) => settingsFormik.setFieldValue("general.additionalIds[1]", event.target.value)}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Website")} name="general.website"
                        error={Boolean(getFormikField(settingsFormik, "general.website").error)}
                        helperText={getFormikField(settingsFormik, "general.website").error}
                        value={getFormikField(settingsFormik, "general.website").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="general.email"
                        error={Boolean(getFormikField(settingsFormik, "general.email").error)}
                        helperText={getFormikField(settingsFormik, "general.email").error}
                        value={getFormikField(settingsFormik, "general.email").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
        </>
    );
}