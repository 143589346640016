// Customers
import CreateCustomerPopupModal from "./customers/createPopup"
import CreateEditCustomerModal from "./customers/createAndEdit"
import CustomerSearchModal from "./customers/search"
import DeleteCustomerModal from "./customers/delete"
// Customers Terms
import CustomerTermsModal from "./customerTerms"
// Vendors
import CreateVendorPopupModal from "./vendors/createPopup"
import CreateEditVendorModal from "./vendors/createAndEdit"
import DeleteVendorModal from "./vendors/delete"
// Vendor Terms
import VendorTermsModal from "./vendorTerms"
// Orders
import CreateOrderPopupModal from "./orders/createPopup"
import CreateEditOrderModal from "./orders/createAndEdit"
import DeleteOrderModal from "./orders/delete"
// Items
import ItemSearchModal from "./items/search"
import CreateItemPopupModal from "./items/createPopup"
import CreateEditItemModal from "./items/createAndEdit"
import DeleteItemModal from "./items/delete"
// Settings
import SettingModal from "./settings"
import AltShipMethodsModal from "./altShipMethods"
import ShipMethodsModal from "./shipMethods"
import SystemNumbersModal from "./systemNumbers"

/**
 * Modals
 * This should be the single mounting point for all modals. All modals should have a redux slice.
 * The purpose is so that all modals are mounted only once and can be opended from anywhere in the app.
 */
export const Modals = () => {
    return (
        <>
            {/* Customers */}
            <CreateCustomerPopupModal />
            <CreateEditCustomerModal />
            <CustomerSearchModal />
            <DeleteCustomerModal />
            {/* Customers Terms */}
            <CustomerTermsModal />
            {/* Vendors */}
            <CreateVendorPopupModal />
            <CreateEditVendorModal />
            <DeleteVendorModal />
            {/* Vendor Terms */}
            <VendorTermsModal />
            {/* Orders */}
            <CreateOrderPopupModal />
            <CreateEditOrderModal />
            <DeleteOrderModal />
            {/* Items */}
            <CreateEditItemModal />
            <CreateItemPopupModal />
            <ItemSearchModal />
            <DeleteItemModal />
            {/* Settings */}
            <AltShipMethodsModal />
            <ShipMethodsModal />
            <SystemNumbersModal />
            <SettingModal />
        </>
    )
}

export default Modals