import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Item, ItemDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export enum ItemModalMode {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit',
}

export interface ItemModalState {
  openItemModal: boolean
  itemIdToEdit?: string
  deleteItemModal: boolean
  itemToDelete: Item | undefined
  mode: ItemModalMode,
  loading: boolean
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  updateCallback?: (newItem: Item | undefined) => Promise<void>
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  createCallback?: (newItem: Item | undefined) => Promise<void>
  deleteCallback?: (newItem: Item | undefined) => Promise<void>
}

// Initialize state
const initialState: ItemModalState = {
  openItemModal: false,
  itemIdToEdit: undefined,
  deleteItemModal: false,
  itemToDelete: undefined,
  mode: ItemModalMode.CREATE,
  loading: false
}

export const itemModalSlice = createSlice({
  name: "itemModal",
  initialState,
  reducers: {
    openNewItemModal: (state, action: PayloadAction<{
      createCallback?: (user: Item | undefined) => Promise<void>,
    } | undefined>) => ({
      ...state,
      openItemModal: true,
      createCallback: action.payload?.createCallback,
    }),
    openEditItemModal: (state, action: PayloadAction<{
      itemIdToEdit: string,
      mode?: ItemModalMode,
      updateCallback?: (user: Item | undefined) => Promise<void>,
    }>) => ({
      ...state,
      itemIdToEdit: action.payload.itemIdToEdit,
      openItemModal: true,
      updateCallback: action.payload?.updateCallback,
      isEditingItem: true,
      mode: action.payload?.mode || ItemModalMode.EDIT,
    }),
    setMode: (state, action: PayloadAction<ItemModalMode>) => ({
      ...state,
      mode: action.payload
    }),
    closeModal: () => ({
      ...initialState
    }),
    openDeleteItemModal: (state, action: PayloadAction<{
      itemToDelete: Item,
      deleteCallback?: (user: Item | undefined) => Promise<void>,
    }>) => ({
      ...state,
      deleteItemModal: true,
      itemToDelete: action.payload.itemToDelete,
      deleteCallback: action.payload.deleteCallback,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openNewItemModal,
  openEditItemModal,
  closeModal,
  openDeleteItemModal,
  setMode,
  setLoading
} = itemModalSlice.actions
export default itemModalSlice.reducer

// Thunks
export const fetchItemToEdit = (id: string) => async (dispatch: Dispatch): Promise<Item> => {
  try {
    dispatch(setLoading(true))
    const item: Item = await ApiClient.itemById.query(id);
    return item
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteItem = (deleteInput: ItemDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteItem.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}
