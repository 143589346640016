import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PriceBreak from "./PriceBreak";
import { buttonWidth } from "../../constants/form";
import { useFormik } from "formik";
import { itemSchema } from "./schemas/indexSchema";
import { client } from "../../../../util";
import type { Vendor, Item, ItemCreate, VendorSearchInput } from "orderflow-lambdas";
import { pushToast, useDispatch, useSelector } from "../../../../state";
import { closeModal, fetchItemToEdit, ItemModalMode, setLoading, setMode } from "../../../../state/itemModalSlice";
import { useTranslation } from "react-i18next";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../../constants/errors";
import { serverLatencyMessage } from "../../constants/messages";
import ItemDetails from "./ItemDetails";
import Assembly from "./Assembly";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const initialValues: ItemCreate = {
    details: {
        category: '',
        taxable: false,
        commissionable: false,
        assemblyItem: false,
        description: '',
        itemLink: '',
        vendorCost: 0,
        customerPrice: 0,
        primaryVendor: '',
        vendorItemNumber: '',
        customerItemNumber: '',
        unit: '',
        onHand: 0,
        minimumRequirements: 0
    },
    assembly: [],
    priceBreaks: []
};


export default function CreateEditItemModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Items.createAndEdit.Add Item" })

    const { mode, openItemModal, itemIdToEdit, updateCallback, createCallback, loading } = useSelector(({ itemModalSlice }) => itemModalSlice);
    const { team } = useSelector(({ userSlice }) => userSlice);

    const [tab, setTab] = useState(0);

    const [vendors, setVendors] = useState<Vendor[]>([]);

    const dispatch = useDispatch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const resetState = () => {
        setTab(0);
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const updateLoading = (value: boolean) => {
        dispatch(setLoading(value))
    }

    const exitViewMode = async () => {
        dispatch(setMode(ItemModalMode.EDIT))
    }

    const handleSave = async (payload: ItemCreate) => {
        updateLoading(true)
        if (mode == ItemModalMode.EDIT) { // check if modal is in edit mode
            if (itemIdToEdit) { // double check if id exists. Should never happen.
                try {
                    const updatedItem = await client.updateItem.mutate({
                        ...payload,
                        ItemId: itemIdToEdit
                    });
                    if (updateCallback) updateCallback(updatedItem)
                    dispatch(pushToast({
                        message: `Item ${updatedItem?.details.itemLink || ""} updated`,
                        additionalInfo: serverLatencyMessage,
                        type: "success", duration: 7000
                    }));
                } catch (error) {
                    console.error("error", error);
                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: "error" }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: "error" }));
                    }
                }
            } else {
                // this should never happaned but its good to have this
                console.log("ERROR: Vendor id is wrong or missing: ", itemIdToEdit)
                dispatch(pushToast({ message: serverError, type: "error" }));
            }

        } else {
            try {
                if (team === "init" || team === "loading") return
                const newItem: Item = await client.createItem.mutate({
                    ...payload,
                });
                if (createCallback) createCallback(newItem)
                dispatch(pushToast({
                    message: `Vendor ${newItem?.details.vendorItemNumber || ""} created`,
                    additionalInfo: serverLatencyMessage,
                    type: "success", duration: 7000
                }));
            }
            catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
        }
        handleClose()

        updateLoading(false)
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: itemSchema,
        onSubmit: handleSave,
    });

    useEffect(() => {
        const fetchData = async () => {
            updateLoading(true)
            if (itemIdToEdit) {
                try {
                    const item = await dispatch(fetchItemToEdit(itemIdToEdit))
                    formik.setValues({
                        ...formik.values,  // Keeping any other existing form values
                        ...item        // Seting item data returned from API
                    });
                } catch (error) {
                    handleClose()

                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: "error" }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: "error" }));
                    }
                }
            }
            updateLoading(false);
        };
        fetchData();
    }, [itemIdToEdit]);

    useEffect(() => {
        if (!openItemModal) return;
        const fetchData = async () => {
            updateLoading(true)
            // Get Items
            try {
                const payload: VendorSearchInput = { query: '', searchBy: 'vendorName', size: 1000, page: 0 }
                const resp = await client.searchVendors.query(payload);
                setVendors(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
                handleClose()
            }
            updateLoading(false);
        };
        fetchData();
    }, [openItemModal]);

    const renderTitle = () => {
        switch (mode) {
            case ItemModalMode.EDIT:
                return tAddC("Edit Item")
            case ItemModalMode.VIEW:
                return tAddC("View Item")
            case ItemModalMode.CREATE:
                return tAddC("Add Item")
            default:
                return ""
        }
    }
    return (
        <Dialog
            open={openItemModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{renderTitle()}</DialogTitle>
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 1 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Container>
                            <Tabs centered value={tab} onChange={handleTabChange} sx={{
                                "& .MuiTabs-flexContainer": {
                                    justifyContent: "space-between",
                                },
                            }}>
                                <Tab sx={{ fontWeight: "bold" }} label="Item Details" {...a11yProps(0)} />
                                <Tab sx={{ fontWeight: "bold" }} label="Price Break" {...a11yProps(1)} />
                                <Tab sx={{ fontWeight: "bold" }} label="Assembly" {...a11yProps(2)} />
                            </Tabs>
                        </Container>
                        <TabPanel value={tab} index={0}>
                            <ItemDetails formik={formik} vendors={vendors} viewMode={mode == ItemModalMode.VIEW} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <PriceBreak formik={formik} viewMode={mode == ItemModalMode.VIEW} />
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <Assembly formik={formik} viewMode={mode == ItemModalMode.VIEW} />
                        </TabPanel>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "space-between" }}>
                    {mode == ItemModalMode.VIEW ? (
                        <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                            exitViewMode()
                        }}>{loading ? <CircularProgress size={20} /> : tGlobal("Edit")}</Button>) : (
                        <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                            formik.submitForm()
                        }}>{loading ? <CircularProgress size={20} /> : tGlobal("Save")}</Button>
                    )}
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}
