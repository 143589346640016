import { useEffect, useState } from "react";
import { Box, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Divider, TextField, Tooltip, Radio, MenuItem } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import usaStates from "../../../static/usaStates.json";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { buttonWidth, textFieldProps, textFieldSelectProps } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/altShipMethodsModalSlice";
import { getFormikField } from "../../../util/fornik-helpers";
import { altShipMethodsSchema } from "./schemas/AltShipMethodsSchema";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../constants/errors";
import { client } from "../../../util";
import { Customer } from "../../../../../../packages/orderflow-lambdas/src/shared/api";

const columnPadding = "0 2px"
type AlternateShippingMethod = any

type AltShipMethodWithId = AlternateShippingMethod & { id: string };

const initialValues: AlternateShippingMethod = {
    address: "",
    state: "",
    city: "",
    zipCode: "",
    country: "US",
    email: "",
    phone: "",
    contactName: "",
    companyName: "",
};

export default function AltShipMethodsModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tMA } = useTranslation([], { keyPrefix: "team.Modals.AltShipMethods" })

    const dispatch = useDispatch();

    const { openAltShipMethodsModal, customer, loading } = useSelector(({ altShipMethodsModalSlice }) => altShipMethodsModalSlice);

    const [currentCustomer, setCurrentCustomer] = useState<Customer>();
    const [altShipMethods, setAltShipMethods] = useState<AltShipMethodWithId[]>([]);
    const [altShipMethodsBeforeEdit, setAltShipMethodsBeforeEdit] = useState<AlternateShippingMethod>();

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);

    const genUUID = () => {
        let uuid = uuidv4()
        return uuid
    }

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleAddAltShipMethod = async (payload: AlternateShippingMethod) => {
        dispatch(setLoading(true))
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0 && formik.isValid) {  // Ensures there are no validation errors
                const aSM: AltShipMethodWithId = { ...formik.values, id: genUUID() };
                const updatedContacts = [...altShipMethods || [], aSM];

                setAltShipMethods(updatedContacts)
                formik.resetForm();
            } else {
                // Making sure to touch all fields to show errors
                formik.setTouched({
                    address: true,
                    state: true,
                    city: true,
                    zipCode: true,
                    country: true,
                    email: true,
                    phone: true,
                    contactName: true,
                    companyName: true,
                });
            }
        });
        dispatch(setLoading(false))
    }

    const saveASMs = async () => {
        dispatch(setLoading(true))
        if (!currentCustomer) return;
        let payload = currentCustomer
        // payload.alternateShipping = altShipMethods
        try {
            await client.updateCustomer.mutate(payload)
            dispatch(pushToast({ message: tMA("Saved successfully!"), type: "success" }));
            handleClose()
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: altShipMethodsSchema,
        onSubmit: handleAddAltShipMethod,
    });

    const columns: GridColDef[] = [
        { field: "address", headerName: tGC("Address"), flex: 1 },
        { field: "state", headerName: tGC("State"), flex: 1 },
        { field: "contactName", headerName: tGC("Contact Name"), flex: 1 },
        { field: "companyName", headerName: tGC("Company Name"), flex: 1 },
        { field: "phone", headerName: tGC("Tel. No."), flex: 1 },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            flex: 0.5,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditAltShipMethod(params.id)
                };

                const handleDelete = () => {
                    handleDeleteAltShipMethod(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const handleDeleteAltShipMethod = async (id: any) => {
        dispatch(setLoading(true))
        const aSMs = altShipMethods?.filter(altShipMethod => altShipMethod.id !== id);

        setAltShipMethods(aSMs)
        formik.resetForm();

        dispatch(setLoading(false))
    }

    const handleEditAltShipMethod = (id: any) => {
        const selectedAltShipMethod = altShipMethods.find((altShipMethod) => altShipMethod.id === id);
        if (selectedAltShipMethod) {
            formik.setValues(selectedAltShipMethod)
            setAltShipMethodsBeforeEdit(selectedAltShipMethod)

            const aSMs = altShipMethods?.filter(altShipMethod => altShipMethod.id !== id);
            setAltShipMethods(aSMs)

            setEditMode(true)
        } else {
            console.log("altShipMethod not found with ID:", id);
        }
    };

    const handleEditAltShipMethodSave = async () => {
        dispatch(setLoading(true))
        const newASM = { ...formik.values, id: genUUID() };
        const updatedASM = [...altShipMethods || [], newASM];
        setAltShipMethods(updatedASM)

        formik.resetForm();
        setAltShipMethodsBeforeEdit(undefined);
        setEditMode(false);


        dispatch(setLoading(false))
    };

    const handleEditAltShipMethodCancel = async () => {
        const oldContact = { ...altShipMethodsBeforeEdit, id: genUUID() };
        const updatedASM = [...altShipMethods || [], oldContact];

        setAltShipMethods(updatedASM)
        formik.resetForm();
        setEditMode(false)
    };

    useEffect(() => {
        if (!openAltShipMethodsModal) return;
        if (!customer) {
            console.log("missing customer")
            handleClose()
            return
        }
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                const resp = await client.customerById.query(customer.CustomerId);
                setCurrentCustomer(resp)

            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, [openAltShipMethodsModal]);

    return (
        <Dialog
            open={openAltShipMethodsModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{"Alternate Ship"}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                            <div className="grid grid-flow-col grid-rows-5 gap-4 md:w-3/6 webkit-box">
                                <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="address"
                                    error={Boolean(getFormikField(formik, "address").error)}
                                    helperText={getFormikField(formik, "address").error}
                                    value={getFormikField(formik, "address").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="city"
                                    error={Boolean(getFormikField(formik, "city").error)}
                                    helperText={getFormikField(formik, "city").error}
                                    value={getFormikField(formik, "city").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="state"
                                    error={Boolean(getFormikField(formik, "state").error)}
                                    helperText={getFormikField(formik, "state").error}
                                    value={getFormikField(formik, "state").value} onChange={formik.handleChange}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {usaStates.map((state) => (
                                        <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} name="country" select
                                    error={Boolean(getFormikField(formik, "country").error)}
                                    helperText={getFormikField(formik, "country").error}
                                    value={getFormikField(formik, "country").value} onChange={formik.handleChange}
                                >
                                    <MenuItem value="US">US</MenuItem>
                                </TextField>
                                <TextField variant="filled" {...textFieldProps} label={tGC("Zip Code")} name="zipCode"
                                    error={Boolean(getFormikField(formik, "zipCode").error)}
                                    helperText={getFormikField(formik, "zipCode").error}
                                    value={getFormikField(formik, "zipCode").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="phone"
                                    error={Boolean(getFormikField(formik, "phone").error)}
                                    helperText={getFormikField(formik, "phone").error}
                                    value={getFormikField(formik, "phone").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="email"
                                    error={Boolean(getFormikField(formik, "email").error)}
                                    helperText={getFormikField(formik, "email").error}
                                    value={getFormikField(formik, "email").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Contact Name")} name="contactName"
                                    error={Boolean(getFormikField(formik, "contactName").error)}
                                    helperText={getFormikField(formik, "contactName").error}
                                    value={getFormikField(formik, "contactName").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Company Name")} name="companyName"
                                    error={Boolean(getFormikField(formik, "companyName").error)}
                                    helperText={getFormikField(formik, "companyName").error}
                                    value={getFormikField(formik, "companyName").value} onChange={formik.handleChange}
                                />
                            </div>
                            <Box sx={{ paddingX: 2 }}>
                                <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                    {editMode ? (
                                        <>
                                            <Tooltip title={tGlobal("Save")}>
                                                <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditAltShipMethodSave}>
                                                    <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={tGlobal("Cancel")}>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={handleEditAltShipMethodCancel}>
                                                    <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip></>
                                    ) : (
                                        <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Alternate Ship")} placement="bottom">
                                            <AddCircleIcon onClick={() => { formik.submitForm() }} cursor={"pointer"} fontSize={"large"} />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Box >
                        <Typography color="primary" sx={{ marginTop: 2 }} >{"Terms"}</Typography>
                        <Divider />
                        <div style={{ height: 400, width: "100%" }}>
                            <DataGridPro
                                sx={{
                                    border: 0, // Removes all borders
                                    "& .MuiDataGrid-cell": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" }
                                }}
                                hideFooter
                                disableColumnSorting
                                disableColumnFilter
                                rows={altShipMethods && altShipMethods.map((altShipMethod) => {
                                    return {
                                        id: altShipMethod.id,
                                        address: altShipMethod.address,
                                        state: altShipMethod.state,
                                        city: altShipMethod.city,
                                        zipCode: altShipMethod.zipCode,
                                        country: altShipMethod.country,
                                        email: altShipMethod.email,
                                        phone: altShipMethod.phone,
                                        contactName: altShipMethod.contactName,
                                        companyName: altShipMethod.companyName,
                                    }
                                })}
                                columns={columns}
                                pageSizeOptions={[altShipMethods?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                            />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                        saveASMs()
                    }}>{loading ? <CircularProgress size={20} /> : tGlobal("Save")}</Button>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}
