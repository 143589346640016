import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Vendor, VendorDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export enum VendorModalMode {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit',
}

export interface VendorModalState {
  openVendorModal: boolean
  vendorIdToEdit?: string
  deleteVendorModal: boolean
  vendorToDelete: Vendor | undefined
  mode: VendorModalMode;
  loading: boolean
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  updateCallback?: (newVendor: Vendor | undefined) => Promise<void>
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  createCallback?: (newVendor: Vendor | undefined) => Promise<void>
  deleteCallback?: (newVendor: Vendor | undefined) => Promise<void>
}

// Initialize state
const initialState: VendorModalState = {
  openVendorModal: false,
  vendorIdToEdit: undefined,
  deleteVendorModal: false,
  vendorToDelete: undefined,
  mode: VendorModalMode.CREATE,
  loading: false
}

export const vendorModalSlice = createSlice({
  name: "vendorModal",
  initialState,
  reducers: {
    openNewVendorModal: (state, action: PayloadAction<{
      createCallback?: (user: Vendor | undefined) => Promise<void>,
    } | undefined>) => ({
      ...state,
      openVendorModal: true,
      createCallback: action.payload?.createCallback,
    }),
    openEditVendorModal: (state, action: PayloadAction<{
      vendorIdToEdit: string,
      mode?: VendorModalMode,
      updateCallback?: (user: Vendor | undefined) => Promise<void>,
    }>) => ({
      ...state,
      vendorIdToEdit: action.payload.vendorIdToEdit,
      openVendorModal: true,
      updateCallback: action.payload?.updateCallback,
      isEditingVendor: true,
      mode: action.payload?.mode || VendorModalMode.EDIT,
    }),
    setMode: (state, action: PayloadAction<VendorModalMode>) => ({
      ...state,
      mode: action.payload
    }),
    closeModal: () => ({
      ...initialState
    }),
    openDeleteVendorModal: (state, action: PayloadAction<{
      vendorToDelete: Vendor,
      deleteCallback?: (user: Vendor | undefined) => Promise<void>,
    }>) => ({
      ...state,
      deleteVendorModal: true,
      vendorToDelete: action.payload.vendorToDelete,
      deleteCallback: action.payload.deleteCallback,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openNewVendorModal,
  openEditVendorModal,
  closeModal,
  openDeleteVendorModal,
  setMode,
  setLoading
} = vendorModalSlice.actions
export default vendorModalSlice.reducer

// Thunks
export const fetchVendorToEdit = (id: string) => async (dispatch: Dispatch): Promise<Vendor> => {
  try {
    dispatch(setLoading(true))
    const vendor: Vendor = await ApiClient.vendorById.query(id);
    return vendor
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteVendor = (deleteInput: VendorDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteVendor.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}
