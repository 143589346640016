import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {
  AutocompleteInputChangeReason
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { client } from '../../util/api-client';
import { Customer, CustomerSearchInput } from 'orderflow-lambdas';
import { debounce } from '@mui/material/utils';
import { pushToast, useDispatch } from '../../state';
import { TRPCClientError } from '@trpc/client';
import { serverError } from '../modals/constants/errors';

type CustomerOption = Customer | { inputValue: string };

interface Props {
  label: string;
  value: Customer | null;
  onChange: (customer: Customer | null) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const SelectSearchCustomer: React.FC<Props> = ({
  label,
  value,
  onChange,
  error,
  helperText,
  disabled
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly CustomerOption[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const searchCustomers = async (searchQuery: string) => {
    try {
      const payload: CustomerSearchInput = {
        query: searchQuery,
        searchBy: 'companyName',
        size: 20,
        page: 0
      };
      const resp = await client.searchCustomers.query(payload);

      let updated: CustomerOption[] = resp.results;

      // Add an "Add " option if no exact match
      const hasExactMatch = resp.results.some(
        (cust) =>
          cust.details.companyName.toLowerCase() === searchQuery.toLowerCase()
      );

      if (!hasExactMatch && searchQuery.trim() !== '') {
        updated.push({ inputValue: searchQuery });
      }

      setOptions(updated);
    } catch (error) {
      if (error instanceof TRPCClientError) {
        dispatch(pushToast({ message: error.message, type: "error" }));
      } else {
        dispatch(pushToast({ message: serverError, type: "error" }));
      }
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = React.useCallback(
    debounce((query: string) => {
      searchCustomers(query);
    }, 1100),
    []
  );

  const onInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // We only want to trigger search on actual text input
    if (reason === 'input') {
      setLoading(true);
      debouncedSearch(newInputValue);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
    searchCustomers('');
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: CustomerOption | null
  ) => {
    if (!newValue) {
      onChange(null);
      return;
    }

    // If the user picked the "Add " item:
    if ('inputValue' in newValue) {
      setLoading(true)
      const newCustomerName = newValue.inputValue;
      try {
        // creates a new customer
        const createdCustomer = await client.createCustomer.mutate({
          details: {
            companyName: newCustomerName
          }
        });
        // set it as the selected value
        onChange(createdCustomer);
      } catch (error) {
        if (error instanceof TRPCClientError) {
          dispatch(pushToast({ message: error.message, type: "error" }));
        } else {
          dispatch(pushToast({ message: serverError, type: "error" }));
        }
      }
      setLoading(false)
    } else {
      // It's an existing customer
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      // Distinguish real vs add options
      isOptionEqualToValue={(option, val) => {
        // If both are "Add" placeholders, compare inputValue
        if ('inputValue' in option && 'inputValue' in val) {
          return option.inputValue === val.inputValue;
        }
        // Otherwise compare IDs or something unique
        if ('id' in option && 'id' in val) {
          return option.id === val.id;
        }
        return false;
      }}
      getOptionLabel={(option) => {
        if ('inputValue' in option) {
          return `Adding "${option.inputValue}"`;
        }
        return option.details.companyName;
      }}
      options={options}
      loading={loading}
      onInputChange={onInputChange}
      onChange={handleChange}
      renderOption={(optionProps, option) => {
        // This is weird part. It says that key exists but its not in some cases. So we just take it out and don't use it. We generate keys later.
        const { key, ...liProps } = optionProps as {
          key?: React.Key;
        } & React.HTMLAttributes<HTMLLIElement>;

        if ('inputValue' in option) {
          return (
            <li
              key={crypto.randomUUID()}
              {...liProps}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <span>{`Add "${option.inputValue}"`}</span>
            </li>
          );
        }
        return (
          <li
            key={crypto.randomUUID()}
            {...liProps}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {option.details.companyName}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default SelectSearchCustomer;
