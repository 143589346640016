import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Customer, CustomerDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export enum CustomerModalMode {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit',
}

export interface CustomerModalState {
  openCustomerModal: boolean
  customerIdToEdit?: string
  deleteCustomerModal: boolean
  customerToDelete: Customer | undefined
  mode: CustomerModalMode;
  loading: boolean
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  updateCallback?: (newCustomer: Customer | undefined) => Promise<void>
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  createCallback?: (newCustomer: Customer | undefined) => Promise<void>
  deleteCallback?: (newCustomer: Customer | undefined) => Promise<void>
}

// Initialize state
const initialState: CustomerModalState = {
  openCustomerModal: false,
  customerIdToEdit: undefined,
  deleteCustomerModal: false,
  customerToDelete: undefined,
  mode: CustomerModalMode.CREATE,
  loading: false
}

export const customerModalSlice = createSlice({
  name: "customerModal",
  initialState,
  reducers: {
    openNewCustomerModal: (state, action: PayloadAction<{
      createCallback?: (user: Customer | undefined) => Promise<void>,
    } | undefined>) => ({
      ...state,
      openCustomerModal: true,
      createCallback: action.payload?.createCallback,
    }),
    openEditCustomerModal: (state, action: PayloadAction<{
      customerIdToEdit: string,
      mode?:CustomerModalMode,
      updateCallback?: (user: Customer | undefined) => Promise<void>,
    }>) => ({
      ...state,
      customerIdToEdit: action.payload.customerIdToEdit,
      openCustomerModal: true,
      updateCallback: action.payload?.updateCallback,
      isEditingCustomer: true,
      mode: action.payload?.mode || CustomerModalMode.EDIT,
    }),
    setMode: (state, action: PayloadAction<CustomerModalMode>) => ({
      ...state,
      mode: action.payload
    }),
    closeModal: () => ({
      ...initialState
    }),
    openDeleteCustomerModal: (state, action: PayloadAction<{
      customerToDelete: Customer,
      deleteCallback?: (user: Customer | undefined) => Promise<void>,
    }>) => ({
      ...state,
      deleteCustomerModal: true,
      customerToDelete: action.payload.customerToDelete,
      deleteCallback: action.payload.deleteCallback,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openNewCustomerModal,
  openEditCustomerModal,
  setMode,
  closeModal,
  openDeleteCustomerModal,
  setLoading
} = customerModalSlice.actions
export default customerModalSlice.reducer

// Thunks
export const fetchCustomerToEdit = (id: string) => async (dispatch: Dispatch): Promise<Customer> => {
  try {
    dispatch(setLoading(true))
    const customer: Customer = await ApiClient.customerById.query(id);
    return customer
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteCustomer = (deleteInput: CustomerDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteCustomer.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}
