import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { client } from '../../util';
import CloseIcon from "@mui/icons-material/Close";
import { ItemCategories } from 'orderflow-lambdas';
import { Box, IconButton } from '@mui/material';

interface CategoryOption {
    title: string;      // displayed text
    inputValue?: string; // used for "Add 'xxx'"
}

const filter = createFilterOptions<CategoryOption>();

interface Props {
    label: string;
    value: string | null;
    onChange: (category: string | null) => void;
    error?: boolean;
    helperText?: string;
    disabled?: boolean
    variant?: TextFieldProps['variant'];
}

const SelectCategory: React.FC<Props> = ({ label, value, onChange, error, helperText, disabled,variant }) => {
    const [itemCategories, setItemCategories] = useState<ItemCategories>();

    useEffect(() => {
        const fetchData = async () => {
            const resp = await client.getCategories.query();
            setItemCategories(resp);
        };
        fetchData();
    }, []);

    const createCategory = async (newCat: string) => {
        if (!itemCategories) return;

        const updatedCategories = [...itemCategories.categories, newCat];
        // Passes the required argument: { categories: ... }
        const resp = await client.updateCategories.mutate({
            categories: updatedCategories,
        });
        setItemCategories(resp);
    };

    const removeCategory = async (catToRemove: string) => {
        if (!itemCategories) return;

        const updatedCategories = itemCategories.categories.filter(
            (cat) => cat !== catToRemove
        );
        const resp = await client.updateCategories.mutate({
            categories: updatedCategories,
        });
        setItemCategories(resp);
    };

    //Convert the parent’s `value` (a string or null)
    //into the object shape expected by Autocomplete.
    const toCategoryOption = (val: string | null): CategoryOption | null => {
        return val ? { title: val } : null;
    };

    return (
        <Autocomplete<CategoryOption, false, false, true>
            disabled={disabled}
            freeSolo
            // Convert the parent’s string value to the object shape
            value={toCategoryOption(value)}
            // Called any time the user changes the value
            onChange={(event, newValue) => {
                // `newValue` can be a string, a CategoryOption, or null
                if (typeof newValue === 'string') {
                    // User typed a string and pressed Enter
                    onChange(newValue);
                } else if (newValue?.inputValue) {
                    // User selected "Add 'xxx'"
                    createCategory(newValue.inputValue);
                    onChange(newValue.inputValue);
                } else {
                    // User picked an existing option or cleared it
                    onChange(newValue?.title ?? null);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                // If user typed text that doesn't match an existing option,
                // offer "Add 'xxx'"
                const isExisting = options.some(
                    (option) => option.title.toLowerCase() === inputValue.toLowerCase()
                );
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            // Turns array of strings into {title: string} objects
            options={itemCategories?.categories.map((cat) => ({ title: cat })) || []}
            getOptionLabel={(option) => {
                // If it’s just a string, display it
                if (typeof option === 'string') return option;
                // If it's an object with inputValue (for "Add ..."), display that
                if (option.inputValue) return option.inputValue;
                // Otherwise, display the `title`
                return option.title;
            }}
            renderOption={(props, option) => {
                // Force props to also contain an optional `key`.
                const { key: itemKey, ...otherProps } = props as {
                    key?: React.Key;
                } & React.HTMLAttributes<HTMLLIElement>;

                // If `option.inputValue` is defined, then this is the "Add 'xxx'" option
                const isAddOption = !!option.inputValue;

                return (
                    <li key={itemKey} {...props}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <span>{option.title}</span>
                            {!isAddOption && (
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation(); // prevent selecting the option
                                        removeCategory(option.title);
                                    }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    variant={variant}
                    {...params}
                    label={label}
                    error={error}
                    helperText={helperText}
                />
            )}
            sx={{ width: 300 }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
        />
    );
};

export default SelectCategory;
