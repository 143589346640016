import { Typography, TextField, Grid, MenuItem, Divider, Stack, Checkbox, FormControlLabel, Box } from "@mui/material";
import type { ItemCreate, Vendor } from "orderflow-lambdas";
import { gridItemProps, textFieldProps } from "../../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";
import { gridPaymentItemProps } from "./constants";
import SelectCategory from "../../../utils/SelectCategory";

export default function ItemDetails({
    formik,
    vendors,
    viewMode,
}: {
    formik: FormikProps<ItemCreate>;
    vendors: Vendor[];
    viewMode: boolean;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddPB } = useTranslation([], { keyPrefix: "team.Modals.Items.createAndEdit.Add Item" })

    const handleChangeCategory = (category: string | null) => {
        if (category != null) {
            formik.setFieldValue('details.category', category);
        } else {
            formik.setFieldValue('details.category', '');
        }
    }

    return (
        <>
            <Typography color="primary">{'Item Detail'}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack gap={2} direction={"row"} justifyContent={"space-around"} width={"100%"}>
                <Stack gap={2} width={"100%"}>
                    <Grid {...gridItemProps}>
                        <SelectCategory
                            variant="filled"
                            disabled={viewMode}
                            label={tAddPB("Category")}
                            onChange={handleChangeCategory}
                            error={Boolean(getFormikField(formik, "details.category").error)}
                            helperText={getFormikField(formik, "details.category").error}
                            value={getFormikField(formik, "details.category").value}
                        />
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"300px"} sx={{ display: "flex", justifyContent: "start" }}>
                            <FormControlLabel
                                disabled={viewMode}
                                control={
                                    <Checkbox
                                        checked={getFormikField(formik, "details.taxable").value}
                                    />
                                }
                                label={tAddPB("Taxable")} name="details.taxable"
                                onChange={formik.handleChange}
                            />
                            <FormControlLabel
                                disabled={viewMode}
                                control={
                                    <Checkbox
                                        checked={getFormikField(formik, "details.commissionable").value}
                                    />
                                }
                                label={tAddPB("Commissionable")} name="details.commissionable"
                                onChange={formik.handleChange} />
                        </Box>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"300px"} sx={{ display: "flex", justifyContent: "start" }}>
                            <FormControlLabel
                                disabled={viewMode}
                                control={
                                    <Checkbox
                                        checked={getFormikField(formik, "details.assemblyItem").value}
                                    />
                                }
                                label={tAddPB("Assembly Item")} name="details.assemblyItem"
                                onChange={formik.handleChange} />
                        </Box>
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tGC("Description")} required name="details.description" disabled={viewMode}
                            error={Boolean(getFormikField(formik, "details.description").error)}
                            helperText={getFormikField(formik, "details.description").error}
                            value={getFormikField(formik, "details.description").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddPB("Item Link")} name="details.itemLink" disabled={viewMode}
                            error={Boolean(getFormikField(formik, "details.itemLink").error)}
                            helperText={getFormikField(formik, "details.itemLink").error}
                            value={getFormikField(formik, "details.itemLink").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddPB("Vendor Cost")} name="details.vendorCost" disabled={viewMode} type="number"
                            error={Boolean(getFormikField(formik, "details.vendorCost").error)}
                            helperText={getFormikField(formik, "details.vendorCost").error}
                            value={getFormikField(formik, "details.vendorCost").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField required variant="filled" {...textFieldProps} label={tAddPB("Customer Cost")} name="details.customerPrice" disabled={viewMode} type="number"
                            error={Boolean(getFormikField(formik, "details.customerPrice").error)}
                            helperText={getFormikField(formik, "details.customerPrice").error}
                            value={getFormikField(formik, "details.customerPrice").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddPB("Primary Vendor")} name="details.primaryVendor" disabled={viewMode} select
                            error={Boolean(getFormikField(formik, "details.primaryVendor").error)}
                            helperText={getFormikField(formik, "details.primaryVendor").error}
                            value={getFormikField(formik, "details.primaryVendor").value} onChange={formik.handleChange}
                        >
                            <MenuItem value={""}>{tGC("None")}</MenuItem>
                            {vendors.map((vendor) => {
                                return (
                                    <MenuItem key={vendor.VendorId} value={vendor.VendorId}>
                                        {vendor.code}
                                    </MenuItem>
                                )
                            })
                            }
                        </TextField>
                    </Grid>
                </Stack>
                <Stack gap={2} width={"100%"} className="flex justify-self-center">
                    <Grid {...gridItemProps}>
                        <TextField required variant="filled" {...textFieldProps} label={tAddPB("Vendor Item No.")} name="details.vendorItemNumber" disabled={viewMode}
                            error={Boolean(getFormikField(formik, "details.vendorItemNumber").error)}
                            helperText={getFormikField(formik, "details.vendorItemNumber").error}
                            value={getFormikField(formik, "details.vendorItemNumber").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField required variant="filled" {...textFieldProps} label={tAddPB("Customer Item No.")} name="details.customerItemNumber" disabled={viewMode}
                            error={Boolean(getFormikField(formik, "details.customerItemNumber").error)}
                            helperText={getFormikField(formik, "details.customerItemNumber").error}
                            value={getFormikField(formik, "details.customerItemNumber").value} onChange={formik.handleChange}
                        />
                    </Grid>
                </Stack>
            </Stack>
            <Divider sx={{ marginBottom: 3, marginTop: 3 }} />
            <Stack gap={2} direction={"row"} justifyContent={"space-around"} width={"100%"}>
                <Stack gap={2} width={"100%"}>
                    <Grid {...gridItemProps}>
                        <TextField required variant="filled" {...textFieldProps} label={tAddPB("Unit")} name="details.unit" disabled={viewMode} type="text"
                            error={Boolean(getFormikField(formik, "details.unit").error)}
                            helperText={getFormikField(formik, "details.unit").error}
                            value={getFormikField(formik, "details.unit").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddPB("Minimum Requirements")} name="details.minimumRequirements" disabled={viewMode} type="number"
                            error={Boolean(getFormikField(formik, "details.minimumRequirements").error)}
                            helperText={getFormikField(formik, "details.minimumRequirements").error}
                            value={getFormikField(formik, "details.minimumRequirements").value} onChange={formik.handleChange}
                        />
                    </Grid>
                </Stack>
                <Stack gap={2} width={"100%"} className="flex justify-self-center">
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddPB("On Hand")} type="number" name="details.onHand" disabled={viewMode}
                            error={Boolean(getFormikField(formik, "details.onHand").error)}
                            helperText={getFormikField(formik, "details.onHand").error}
                            value={getFormikField(formik, "details.onHand").value} onChange={formik.handleChange}
                        />
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
}