import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OrderDetails from './OrderDetails';
import { buttonWidth } from '../../constants/form';
import { useFormik } from 'formik';
import { orderSchema } from './schemas/indexSchema';
import { client } from '../../../../util';
import type { Order, OrderCreateInput, PaymentTerms, ShipMethods, TaxCode } from 'orderflow-lambdas';
import { pushToast, useDispatch, useSelector } from '../../../../state';
import { closeModal, fetchOrderToEdit, OrderModalMode, setFormData, setLoading, setMode } from '../../../../state/orderModalSlice';
import { useTranslation } from 'react-i18next';
import { TRPCClientError } from '@trpc/client';
import { serverError } from '../../constants/errors';
import { serverLatencyMessage } from '../../constants/messages';
import Items from './Items';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CreateEditOrderModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tAddO } = useTranslation([], { keyPrefix: 'team.Modals.Orders.createAndEdit.Add Order' })

    const { mode, stateIsChanged, formData, isOpenOrderModal, orderIdToEdit, updateCallback, createCallback, loading } = useSelector(({ orderModalSlice }) => orderModalSlice);
    const { team } = useSelector(({ userSlice }) => userSlice);

    const [tab, setTab] = useState(0);

    const dispatch = useDispatch();

    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms[]>([]);
    const [shipMethods, setShipMethods] = useState<ShipMethods[]>([]);

    const updateLoading = (value: boolean) => {
        dispatch(setLoading(value))
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const resetState = () => {
        setTab(0);
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal({ resetState: true }));
        resetState();
    };

    const exitViewMode = async () => {
        dispatch(setMode(OrderModalMode.EDIT))
    }

    const handleSave = async (payload: OrderCreateInput) => {
        updateLoading(true)
        if (mode == OrderModalMode.EDIT) { // check if modal is in edit mode
            if (orderIdToEdit) { // double check if id exists. Should never happen.
                try {
                    const updatedOrder = await client.updateOrder.mutate({
                        ...payload,
                        rate: payload.rate / 100,
                        OrderId: orderIdToEdit
                    });
                    if (updateCallback) updateCallback(updatedOrder)
                    dispatch(pushToast({
                        message: `Order ${updatedOrder?.code || ''} updated`,
                        additionalInfo: serverLatencyMessage,
                        type: 'success', duration: 7000
                    }));
                } catch (error) {
                    console.error('error', error);
                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: 'error' }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: 'error' }));
                    }
                }
            } else {
                // this should never happaned but its good to have this
                console.log('ERROR: Order id is wrong or missing: ', orderIdToEdit)
                dispatch(pushToast({ message: serverError, type: 'error' }));
            }

        } else {
            try {
                if (team === 'init' || team === 'loading') return
                const newOrder: Order = await client.createOrder.mutate({
                    ...payload,
                    rate: payload.rate / 100
                });
                if (createCallback) createCallback(newOrder)
                dispatch(pushToast({
                    message: `Order ${newOrder?.OrderId || ''} created`,
                    additionalInfo: serverLatencyMessage,
                    type: 'success', duration: 7000
                }));
            }
            catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: 'error' }));
                } else {
                    dispatch(pushToast({ message: serverError, type: 'error' }));
                }
            }
        }
        handleClose()
        updateLoading(false)
    }

    // Use formData from redux as initialValues
    const formik = useFormik({
        initialValues: formData,
        enableReinitialize: false, // Do not Reinitialize form values when formData in redux changes
        validationSchema: orderSchema,
        onSubmit: handleSave,
    });

    // Sync formik values back to Redux when they change
    useEffect(() => {
        dispatch(setFormData(formik.values));
    }, [formik.values]);

    const fetchOptions = async () => {
        updateLoading(true)
        try {
            const [shipMethods, paymentTerms] = await Promise.all([
                client.allShippingMethods.query(),
                client.allPaymentTerms.query()
            ]);
            setShipMethods(shipMethods.results)
            setPaymentTerms(paymentTerms.results)
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: 'error' }));
            } else {
                dispatch(pushToast({ message: serverError, type: 'error' }));
            }
            handleClose()
        }
        updateLoading(false);
    }

    // After fetching order when editing:
    useEffect(() => {
        const fetchData = async () => {
            if (orderIdToEdit) {
                fetchOptions()
                try {
                    const order = await dispatch(fetchOrderToEdit(orderIdToEdit));
                    // Once we have the order, set it into the formData
                    formik.setValues({
                        ...formik.values,  // Keeping any other existing form values
                        ...order        // Seting vendor data returned from API
                    });
                } catch (error) {
                    handleClose();

                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: 'error' }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: 'error' }));
                    }
                }
            }
        };
        fetchData();
    }, [orderIdToEdit, dispatch]);

    useEffect(() => {
        // Here we don't make api calls if modal sate not changed (came back from other modal)
        // We also need to chek if its in edit mode because the state will be changed in it but we still need to fetch
        if (!isOpenOrderModal) return;
        if (stateIsChanged) return;
        const fetchData = async () => {
            fetchOptions()
        };
        // Getting Initial data from formData (redux)
        formik.setValues(formData)
        fetchData();
    }, [isOpenOrderModal]);

    const renderTitle = () => {
        switch (mode) {
            case OrderModalMode.EDIT:
                return tAddO("Edit Order")
            case OrderModalMode.VIEW:
                return tAddO("View Order")
            case OrderModalMode.CREATE:
                return tAddO("Add Order")
            default:
                return ""
        }
    }

    // This functions set default values
    useEffect(() => {
        if (paymentTerms.length > 0) {
            const defaultTerm = paymentTerms.find((pT) => pT.default);
            if (defaultTerm) {
                formik.setFieldValue('paymentTerms', [defaultTerm]);
            }
        }
        if (shipMethods.length > 0) {
            const defaultCode = shipMethods.find((sM) => sM.default);
            if (defaultCode) {
                formik.setFieldValue('shipMethod', defaultCode.ShipMethodId);
            }
        }
    }, [paymentTerms, shipMethods]); // Only run when `paymentTerms` or `shipMethods` changes

    return (
        <Dialog
            open={isOpenOrderModal}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                handleClose()
            }}
            maxWidth='lg'
            scroll='paper'
            PaperProps={{ className: 'w-full' }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{renderTitle()}</DialogTitle>
            <IconButton disabled={loading} color='primary' sx={{ position: 'absolute', right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 1 }} >
                {loading ? (
                    <Box height='400px' display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Container>
                            <Tabs centered value={tab} onChange={handleTabChange} sx={{
                                '& .MuiTabs-flexContainer': {
                                    justifyContent: 'space-around',
                                },
                            }}>
                                <Tab sx={{ fontWeight: 'bold', width: '100%' }} label={tAddO('ORDER DETAILS')} {...a11yProps(0)} />
                                <Tab sx={{ fontWeight: 'bold', width: '100%' }} label={tAddO('ADD ITEM')} {...a11yProps(1)} />
                            </Tabs>
                        </Container>
                        <TabPanel value={tab} index={0}>
                            <OrderDetails formik={formik} paymentTerms={paymentTerms} shipMethods={shipMethods} viewMode={mode == OrderModalMode.VIEW} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <Items formik={formik} viewMode={mode == OrderModalMode.VIEW} />
                        </TabPanel>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Box width='100%' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {mode == OrderModalMode.VIEW ? (
                        <Button disabled={loading} variant='contained' color='primary' sx={{ width: buttonWidth }} onClick={() => {
                            exitViewMode()
                        }}>{loading ? <CircularProgress size={20} /> : tGlobal('Edit')}</Button>) : (
                        <Button disabled={loading} variant='contained' color='primary' sx={{ width: buttonWidth }} onClick={() => {
                            formik.submitForm()
                        }}>{loading ? <CircularProgress size={20} /> : tGlobal('Save')}</Button>
                    )}
                    <Button disabled={loading} variant='outlined' color='primary' sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal('Cancel')}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}
