import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from '../../state';
import { ItemModalMode, openDeleteItemModal, openEditItemModal } from "../../state/itemModalSlice";
import { Item } from 'orderflow-lambdas';

interface BasicMenuProps {
  item: Item
}

export default function BasicMenu(props: BasicMenuProps) {
  // const {searchInput, searchBy} = useSelector((store) => store.itemBrowseSlice)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditItem = () => {
    dispatch(openEditItemModal({
      itemIdToEdit: props.item.ItemId,
      updateCallback: async (item) => {
        if (item) {
          // Here you can handle updates with props.item and user
        }
      },
    }));
    setAnchorEl(null);
  };

  const handleViewItem = () => {
    dispatch(openEditItemModal({
      itemIdToEdit: props.item.ItemId,
      mode: ItemModalMode.VIEW,
      updateCallback: async (item) => {
        if (item) {
          // Here you can handle updates with props.item and user
        }
      },
    }));
    setAnchorEl(null);
  };


  const handleDeleteItem = () => {
    setAnchorEl(null);
    dispatch(openDeleteItemModal({
      itemToDelete: props.item,
      // it takes too long for changes to propogate after deleting a item

      // deleteCallback: async () => {
      //   const payload:ItemSearchInput = { query: searchInput, searchBy, size: 100, page: 0}
      //   dispatch(searchItems(payload))
      // }
    }))
  }

  const OptionsMUIIconSize = 30;

  return (
    <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='text-2xl tracking-tight border-0 rounded-full'
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditItem} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <EditIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; Edit Item
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <ListIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Order History
        </MenuItem>
        <MenuItem onClick={handleViewItem} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <CategoryIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Item
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <HistoryIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Timeline
        </MenuItem>
        <MenuItem onClick={handleDeleteItem} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <DeleteIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </div>
  );
}