import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridColumnMenuProps,
  GridColumnMenu,
  GridLoadingOverlayProps,
  LoadingOverlayPropsOverrides,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { CircularProgress, LinearProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { searchItems, setSearchInput, setSearchLoading, setSelectedItems } from '../../../../state/itemSearchModalSlice';
import { useDispatch, useSelector } from '../../../../state';
import { ItemSearchInput } from 'orderflow-lambdas';
import { useCallback, useEffect } from 'react';
import { debounce } from '@mui/material/utils'
import SearchByDropdown from './SearchByDropdown';
import { useTranslation } from 'react-i18next';


interface LoaderProps extends Omit<GridLoadingOverlayProps, 'color'>, LoadingOverlayPropsOverrides {
  color?: string;
}

const Loader = (props: LoaderProps) => {
  return <LinearProgress color={'primary'} />;
}


function CustomToolbar() {
  const dispatch = useDispatch()
  const { t: tMIS } = useTranslation([], { keyPrefix: 'team.Modals.ItemsSearch' })
  const { searchInput, searchBy, searchLoading } = useSelector((store) => store.itemSearchModalSlice)
  const { team } = useSelector((store) => store.userSlice)

  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      const payload: ItemSearchInput = { query: searchQuery, searchBy, size: 100, page: 0 }
      dispatch(searchItems(payload))
    }, 1500),
    [searchBy]
  );

  if (team === 'init' || team === 'loading') return null

  return (
    <GridToolbarContainer>
      <SearchByDropdown />
      <TextField
        value={searchInput}
        onChange={(e) => {
          dispatch(setSearchLoading(true))
          dispatch(setSearchInput(e.currentTarget.value))
          debouncedSearch(e.currentTarget.value)
        }}
        id='filled-basic'
        label={tMIS('Search for items...')}
        variant='outlined'
        InputProps={{
          startAdornment: <InputAdornment position='start'><SearchOutlinedIcon /></InputAdornment>,
          endAdornment: <React.Fragment>
            {searchLoading ? <CircularProgress color='inherit' size={20} /> : null}
          </React.Fragment>
        }}
      />
    </GridToolbarContainer>
  );
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null
      }}
    />
  );
}

export default function Table() {
  const dispatch = useDispatch()
  const { t: tGC } = useTranslation([], { keyPrefix: 'team.Global.Common' })
  const { t: tMIS } = useTranslation([], { keyPrefix: 'team.Modals.ItemsSearch' })
  const { resp, searchLoading } = useSelector((store) => store.itemSearchModalSlice);

  const handleSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    // Filter the rows to get the full objects of the selected items
    const rows = resp ? resp.results : [];
    const selected = rows.filter((row) => newSelectionModel.includes(row.ItemId));
    dispatch(setSelectedItems(selected));
  };
  

  const columns: GridColDef[] = [
    {
      field: 'vendorItemNumber',
      headerName: tMIS('Item Code'),
      type: 'string',
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.vendorItemNumber;
      },
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'description',
      headerName: tGC('Description'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.description;
      },
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'unit',
      headerName:  tMIS('Unit'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.unit;
      },
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'customerPrice',
      headerName:  tMIS('Customer Price'),
      type: 'string',
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.customerPrice;
      },
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
  ];

  return (
    <DataGridPro
      sx={{
        border: 'none', padding: '1rem', '.MuiDataGrid-row:nth-of-type(even)': {
          backgroundColor: '#ECECEC;',
        }, '.MuiDataGrid-scrollbar--horizontal': { display: 'none' }, '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }
      }}
      rows={resp ? resp.results.map((item) => ({
        ...item,
        id: item.ItemId,
      })) : []}
      onRowSelectionModelChange={handleSelectionChange}
      loading={searchLoading}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      pinnedColumns={{ right: ['action'] }}
      pageSizeOptions={[1]}
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnSorting
      disableColumnMenu
      autoHeight={false}
      slots={{
        loadingOverlay: Loader,
        toolbar: CustomToolbar,
        columnMenu: CustomColumnMenu
      }}
    />
  );
}