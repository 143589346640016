import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from '../../state';
import { openDeleteOrderModal, openEditOrderModal, OrderModalMode } from "../../state/orderModalSlice";
import { Order } from 'orderflow-lambdas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

interface BasicMenuProps {
  order: Order
}

export default function BasicMenu(props: BasicMenuProps) {
  // const {searchInput, searchBy} = useSelector((store) => store.orderBrowseSlice)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditOrder = () => {
    dispatch(openEditOrderModal({
      orderIdToEdit: props.order.OrderId,
      updateCallback: async (order) => {
        if (order) {
          // Here you can handle updates with props.order and user
        }
      },
    }));
    setAnchorEl(null);
  };

  const handleViewOrder = () => {
    dispatch(openEditOrderModal({
      orderIdToEdit: props.order.OrderId,
      mode: OrderModalMode.VIEW,
      updateCallback: async (order) => {
        if (order) {
          // Here you can handle updates with props.order and user
        }
      },
    }));
    setAnchorEl(null);
  };


  const handleDeleteOrder = () => {
    setAnchorEl(null);
    dispatch(openDeleteOrderModal({
      orderToDelete: props.order,
      // it takes too long for changes to propogate after deleting a order

      // deleteCallback: async () => {
      //   const payload:OrderSearchInput = { query: searchInput, searchBy, size: 100, page: 0}
      //   dispatch(searchOrders(payload))
      // }
    }))
  }

  const OptionsMUIIconSize = 30;
  const OptionsFAIconSize = 21;

  return (
    <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='text-2xl tracking-tight border-0 rounded-full'
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditOrder} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <EditIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; Edit Order
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <ListIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Order History
        </MenuItem>
        <MenuItem onClick={handleViewOrder} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <FontAwesomeIcon icon={faFileSignature} style={{ fontSize: OptionsFAIconSize }} />
          </div>
          &nbsp; View Order
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <HistoryIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Timeline
        </MenuItem>
        <MenuItem onClick={handleDeleteOrder} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <DeleteIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </div>
  );
}