import { Typography, Divider, Button, Link, TextField, Box, useTheme, debounce } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DataGridPro, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridSlotProps, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormikProps } from 'formik';
import { Item, OrderCreateInput, OrderItem } from 'orderflow-lambdas';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from '../../../../state';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { openSearchItemModal } from '../../../../state/itemSearchModalSlice';
import { addItemsToOrder, closeModal, openOrderModal } from '../../../../state/orderModalSlice';
import hexToRGBA from '../../../../util/converters/hexToRGBA';
import { getFormikField } from '../../../../util/fornik-helpers';

const columnPadding = '0 2px'


export default function Items({
    formik,
    viewMode,
}: {
    formik: FormikProps<OrderCreateInput>;
    viewMode: boolean;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: 'team.Global.Common' })
    const { t: tGB } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tAddO } = useTranslation([], { keyPrefix: 'team.Modals.Orders.createAndEdit.Add Order' })

    const theme = useTheme();
    const dispatch = useDispatch()

    const { isOpenOrderModal, formData } = useSelector(({ orderModalSlice }) => orderModalSlice);

    const [items, setItems] = useState<OrderItem[]>([]);

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const onImport = (selectedItems: Item[]) => {
        // Extract current item IDs to identify duplicates
        const currentItemIds = new Set(formData.items.map(item => item.itemId));

        // Mapping Items to Order Item 
        const mappedOrderItems = selectedItems
            .map((item) => {
                const {
                    ItemId,
                    details: {
                        description,
                        unit,
                        vendorCost,
                        customerPrice,
                        vendorItemNumber,
                    },
                } = item;

                return {
                    description,
                    quantityOrdered: 0,
                    quantityShipped: 0,
                    unitNumber: unit,
                    itemId: ItemId,
                    itemCode: vendorItemNumber,
                    vendorCost: vendorCost ?? 0,
                    customerPrice: customerPrice,
                };
            })
            .filter(orderItem => !currentItemIds.has(orderItem.itemId)); // Exclude duplicates

        dispatch(openOrderModal());
        const updatedFromData = {
            ...formData,
            items: [
              ...formData.items,  // keep previously imported items
              ...mappedOrderItems // add new items that aren't duplicates
            ],
          };
        formik.setValues(updatedFromData)
    };

    const onCancel = () => {
        dispatch(openOrderModal());
    };

    function EditToolbar(props: GridSlotProps['toolbar']) {

        const handleClick = () => {
            dispatch(closeModal())
            dispatch(openSearchItemModal({ onImport, onCancel }))
        };

        return (
            <GridToolbarContainer>
                <Button disabled={viewMode} color='primary' sx={{ fontWeight: 'bold' }} endIcon={<AddIcon />} onClick={handleClick}>
                    {tAddO('ADD ITEM')}
                </Button>
            </GridToolbarContainer>
        );
    }


    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        formik.setFieldValue('items', items.filter((item) => item.itemId !== id));
        setItems(items.filter((item) => item.itemId !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        // Find the original row in items
        const originalRow = items.find((item) => item.itemId === newRow.id);

        if (!originalRow) {
            throw new Error('Original row not found.');
        }

        // Merge the original row with the updated data
        const updatedRow = {
            ...originalRow,
            ...newRow, // Update fields from newRow
            isNew: false, // Ensure isNew is set to false
        };

        const updatedItems = items.map((item) =>
            item.itemId === newRow.id ? updatedRow : item
        );

        // Update the state with the modified row
        setItems(updatedItems);

        // Update Formik with the updated items
        formik.setFieldValue('items', updatedItems);

        return updatedRow;
    };


    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        { field: 'quantityOrdered', headerName: tAddO('Quantity Ordered'), editable: true, type: 'number', flex: 1 },
        { field: 'quantityShipped', headerName: tAddO('Quantity Shipped'), editable: true, type: 'number', flex: 1 },
        { field: 'unitNumber', headerName: tAddO('Unit'), editable: true, flex: 1 },
        { field: 'itemCode', headerName: tAddO('Item No.'), editable: true, flex: 1 },
        { field: 'description', headerName: tGC('Description'), editable: true, flex: 1 },
        { field: 'vendorCost', headerName: tAddO('Vendor Cost'), editable: true, type: 'number', flex: 1 },
        { field: 'customerPrice', headerName: tAddO('Customer Price'), editable: true, type: 'number', flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: tGB('Actions'),
            width: 100,
            cellClassName: 'actions',
            flex: 1,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            disabled={viewMode}
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            disabled={viewMode}
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        disabled={viewMode}
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        disabled={viewMode}
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
        },
    ];

    useEffect(() => {
        setItems(formik.values.items)
    }, [isOpenOrderModal, formik.values.items]);


    const totalVendorCost = items
        .reduce((acc, item) => acc + (item.vendorCost * item.quantityOrdered), 0)
        .toFixed(2); // round to two decimal places

    const totalCustomerPrice = items
        .reduce((acc, item) => acc + (item.customerPrice * item.quantityOrdered), 0)
        .toFixed(2); // round to two decimal places

    return (
        <>
            <Typography color='primary' className='flex justify-between' component='div'>
                <span>{tAddO('ITEMS')}</span>
                <Box className='flex'>
                    <Link
                        className='mr-2'
                        color='text.secondary'
                        underline='none'
                        sx={{ cursor: 'pointer' }}
                    >
                        {tAddO('TOTAL VENDOR COST') + ':'}
                    </Link>
                    <span className='font-bold text-black'>{'$' + totalVendorCost}</span>
                    <Link
                        className='mx-2'
                        color='text.secondary'
                        underline='none'
                        sx={{ cursor: 'pointer' }}
                    >
                        {tAddO('TOTAL CUSTOMER PRICE') + ':'}
                    </Link>
                    <span className='font-bold text-black'>{'$' + totalCustomerPrice}</span>
                </Box>
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <div style={{ height: 500, width: '100%' }}>
                <DataGridPro
                    // Turn off row/cell selection on click
                    disableRowSelectionOnClick={viewMode}
                    // Disable column menu
                    disableColumnMenu={viewMode}
                    // Hide the density selector
                    disableDensitySelector={viewMode}
                    // Hide the column selector
                    disableColumnSelector={viewMode}
                    // Optionally disallow multiple-row selection
                    disableMultipleRowSelection={viewMode}
                    // Return false if `viewMode` so cells cannot go into edit mode
                    isCellEditable={(params) => !viewMode}
                    sx={{
                        border: 0, // Removes all borders
                        '& .MuiDataGrid-cell': {
                            padding: columnPadding, // Adjust the padding as needed
                        },
                        '& .MuiDataGrid-columnHeader': {
                            padding: columnPadding, // Adjust the padding as needed
                        }
                    }}
                    onRowEditStop={handleRowEditStop}
                    hideFooter
                    disableColumnSorting
                    disableColumnFilter
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={processRowUpdate}
                    slots={{ toolbar: EditToolbar }}
                    rows={items.map((item) => ({
                        ...item, // Include all fields
                        id: item.itemId, // Add any additional computed fields
                    }))}
                    columns={columns}
                    pageSizeOptions={[items?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                />
            </div>
            < Divider sx={{ marginY: 3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                    disabled={viewMode}
                    variant='filled'
                    label={tAddO('Special Instructions')}
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
                    }}
                    name='specialInstructions'
                    error={Boolean(getFormikField(formik, 'specialInstructions').error)}
                    helperText={getFormikField(formik, 'specialInstructions').error}
                    value={getFormikField(formik, 'specialInstructions').value} onChange={formik.handleChange}
                />
            </Box>
        </>
    );
}
