import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomerAndPayment from "./CustomerAndPayment";
import BillingAndDelivery from "./BillingAndDelivery";
import ContactAndNotes from "./ContactAndNotes";
import { buttonWidth } from "../../constants/form";
import { useFormik } from "formik";
import { customerSchema } from "./schemas/indexSchema";
import { client } from "../../../../util";
import type { Customer, CustomerCreate, PaymentTerms, TaxCode } from "orderflow-lambdas";
import { pushToast, useDispatch, useSelector } from "../../../../state";
import { closeModal, CustomerModalMode, fetchCustomerToEdit, setMode } from "../../../../state/customerModalSlice";
import { useTranslation } from "react-i18next";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../../constants/errors";
import { serverLatencyMessage } from "../../constants/messages";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const initialValues: CustomerCreate = {
    details: {
        companyName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "US",
        zipCode: "",
        preferredCommunication: "",
        website: "",
        other: "",
    },
    payment: {
        currentBalance: 0,
        taxable: false,
        creditLimit: 0,
        exemptCode: "",
        paymentTerms: [],
    },
    billing: {
        companyName: "",
        contactName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "US",
        zipCode: "",
        preferredCommunication: "",
    },
    delivery: [{
        companyName: "",
        contactName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "US",
        zipCode: "",
        preferredCommunication: "",
    }],
    notes: "",
    contacts: [],
};


export default function CreateEditCustomerModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer" })

    const { mode, openCustomerModal, customerIdToEdit, updateCallback, createCallback, loading } = useSelector(({ customerModalSlice }) => customerModalSlice);
    const { team } = useSelector(({ userSlice }) => userSlice);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tab, setTab] = useState(0);

    const dispatch = useDispatch();

    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms[]>([]);
    const [taxCodes, setTaxCodes] = useState<TaxCode[]>([]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const resetState = () => {
        setTab(0);
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        if (createCallback) createCallback(undefined)
        resetState();
    };

    const exitViewMode = async () => {
        dispatch(setMode(CustomerModalMode.EDIT))
    }

    const handleSave = async (payload: CustomerCreate) => {
        setIsLoading(true)
        if (mode == CustomerModalMode.EDIT) { // check if modal is in edit mode
            if (customerIdToEdit) { // double check if id exists. Should never happen.
                try {
                    const updatedCustomer = await client.updateCustomer.mutate({
                        ...payload,
                        CustomerId: customerIdToEdit
                    });
                    if (updateCallback) updateCallback(updatedCustomer)
                    dispatch(pushToast({
                        message: `Customer ${updatedCustomer?.details.companyName || ""} updated`,
                        additionalInfo: serverLatencyMessage,
                        type: "success", duration: 7000
                    }));
                } catch (error) {
                    console.error("error", error);
                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: "error" }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: "error" }));
                    }
                }
            } else {
                // this should never happaned but its good to have this
                console.log("ERROR: Customer id is wrong or missing: ", customerIdToEdit)
                dispatch(pushToast({ message: serverError, type: "error" }));
            }

        } else {
            try {
                if (team === "init" || team === "loading") return
                const newCustomer: Customer = await client.createCustomer.mutate({
                    ...payload,
                });
                if (createCallback) createCallback(newCustomer)
                dispatch(pushToast({
                    message: `Customer ${newCustomer?.details.companyName || ""} created`,
                    additionalInfo: serverLatencyMessage,
                    type: "success", duration: 7000
                }));
            }
            catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
        }
        handleClose()

        setIsLoading(false)
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: customerSchema,
        onSubmit: handleSave,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (customerIdToEdit) {
                try {
                    const customer = await dispatch(fetchCustomerToEdit(customerIdToEdit))
                    // setIsLoading(true)
                    // const customer: Customer = await client.customerById.query(customerIdToEdit);
                    formik.setValues({
                        ...formik.values,  // Keeping any other existing form values
                        ...customer        // Seting customer data returned from API
                    });
                    // setIsLoading(false)
                } catch (error) {
                    handleClose()

                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: "error" }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: "error" }));
                    }
                }
            }
        };
        fetchData();
    }, [customerIdToEdit]);

    useEffect(() => {
        if (!openCustomerModal) return;
        const fetchData = async () => {
            setIsLoading(true)
            // Get Vendor terms
            try {
                const [taxCodes, paymentTerms] = await Promise.all([
                    client.allTaxCodes.query(),
                    client.allPaymentTerms.query()
                ]);
                setTaxCodes(taxCodes.results)
                setPaymentTerms(paymentTerms.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
                handleClose()
            }
            setIsLoading(false);
        };
        fetchData();
    }, [openCustomerModal]);

    const renderTitle = () => {
        switch (mode) {
            case CustomerModalMode.EDIT:
                return tAddC("Edit Customer")
            case CustomerModalMode.VIEW:
                return tAddC("View Customer")
            case CustomerModalMode.CREATE:
                return tAddC("Add Customer")
            default:
                return ""
        }
    }

    // This functions set default values
    useEffect(() => {
        if (paymentTerms.length > 0) {
            const defaultTerm = paymentTerms.find((pT) => pT.default);
            if (defaultTerm) {
                formik.setFieldValue("payment.paymentTerms", [defaultTerm]);
            }
        }
        if (taxCodes.length > 0) {
            const defaultCode = taxCodes.find((pT) => pT.default);
            if (defaultCode) {
                formik.setFieldValue("payment.taxCode", defaultCode);
            }
        }
    }, [paymentTerms, taxCodes]); // Only run when `paymentTerms` or `taxCodes` changes

    return (
        <Dialog
            open={openCustomerModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{renderTitle()}</DialogTitle>
            <IconButton disabled={isLoading || loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 1 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Container>
                            <Tabs centered value={tab} onChange={handleTabChange} sx={{
                                "& .MuiTabs-flexContainer": {
                                    justifyContent: "space-between",
                                },
                            }}>
                                <Tab sx={{ fontWeight: "bold" }} label="Customer & Payment" {...a11yProps(0)} />
                                <Tab sx={{ fontWeight: "bold" }} label="Billing & Delivery" {...a11yProps(1)} />
                                <Tab sx={{ fontWeight: "bold" }} label="Contact & Notes" {...a11yProps(2)} />
                            </Tabs>
                        </Container>
                        <TabPanel value={tab} index={0}>
                            <CustomerAndPayment formik={formik} paymentTerms={paymentTerms} taxCodes={taxCodes} viewMode={mode == CustomerModalMode.VIEW} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <BillingAndDelivery formik={formik} viewMode={mode == CustomerModalMode.VIEW} />
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <ContactAndNotes formik={formik} viewMode={mode == CustomerModalMode.VIEW} />
                        </TabPanel>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "space-between" }}>
                    {mode == CustomerModalMode.VIEW ? (
                        <Button disabled={isLoading || loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                            exitViewMode()
                        }}>{isLoading ? <CircularProgress size={20} /> : tGlobal("Edit")}</Button>) : (
                        <Button disabled={isLoading || loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                            formik.submitForm()
                        }}>{isLoading ? <CircularProgress size={20} /> : tGlobal("Save")}</Button>
                    )}
                    <Button disabled={isLoading || loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}
