import { Typography, TextField, MenuItem, Divider, Box, Tooltip, useTheme, IconButton, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import hexToRGBA from "../../../../util/converters/hexToRGBA";
import { useEffect, useState } from "react";
import { textFieldProps, textFieldSelectProps } from "../../constants/form";
import usaStates from "../../../../static/usaStates.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormikProps, useFormik } from "formik";
import { itemAssemblySchema } from "./schemas/indexSchema";
import { ItemCreate, } from "orderflow-lambdas";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../../state";
import { setLoading } from "../../../../state/vendorModalSlice";
import { ItemAssembly } from "../../../../../../../packages/orderflow-lambdas/src/shared/api";

const columnPadding = "0 2px"

// We create a new type and add id in it because MUI DataGrid works only with ID
type AssemblyWithId = ItemAssembly & { id: string };

export default function Assembly({
    formik,
    viewMode,
}: {
    formik: FormikProps<ItemCreate>;
    viewMode: boolean;
}): JSX.Element {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddPB } = useTranslation([], { keyPrefix: "team.Modals.Items.createAndEdit.Add Item" })
    const theme = useTheme();
    const dispatch = useDispatch();

    // Basically MUI Grid requires Id to handle rows, but provided api structure does not have ids.
    // Because of that we need to create this state and use it for table.
    // In the functions below we update formik on every event. Basically we update 2 arrays at the same time (one has ids).
    const [assembliesWithId, setAssemblyWithId] = useState<AssemblyWithId[]>([]);

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);
    const [assemblyBeforeEdit, setAssemblyBeforeEdit] = useState<ItemAssembly>();

    const { loading } = useSelector(({ vendorModalSlice }) => vendorModalSlice);

    const initialValues: ItemAssembly = {
        description: "",
        cost: 0,
        sequence: 0,
        itemNumber: "",
        quantityRequired: 0,
        extendedPrice: 0,
    };

    const formikAssembly = useFormik({
        initialValues: initialValues,
        validationSchema: itemAssemblySchema,
        onSubmit: (values) => { },
    });

    const components = formik.values.assembly.length;
    let sumCost = 0;

    const columns: GridColDef[] = [
        { field: "sequence", headerName: tAddPB("Sequence"), flex: 1 },
        { field: "itemNumber", headerName: tAddPB("Item No."), flex: 1 },
        { field: "description", headerName: tGC("Description"), flex: 1 },
        { field: "quantityRequired", headerName: tAddPB("Quantity Required"), flex: 1 },
        { field: "cost", headerName: tAddPB("Unit Cost"), flex: 1 },
        { field: "extendedPrice", headerName: tAddPB("Extended Price"), flex: 1 },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            flex: 1,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditAssembly(params.id)

                };

                const handleDelete = () => {
                    handleDeleteAssembly(params.id)
                };

                return (
                    <div>
                        <IconButton disabled={viewMode} onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton disabled={viewMode} onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const updateLoading = (value: boolean) => {
        dispatch(setLoading(value))
    }

    const genUUID = () => {
        let uuid = uuidv4()
        return uuid
    }

    // DELETE
    const handleDeleteAssembly = (id: any) => {
        updateLoading(true)
        const updatedAssemblies = assembliesWithId?.filter(assembly => assembly.id !== id);

        setAssemblyWithId(updatedAssemblies) // Updating the assemblys with Ids state
        formik.setFieldValue("assembly", updatedAssemblies.map(({ id, ...rest }) => rest));  // Updating the main Formik state and removing ids
        updateLoading(false)
    }

    // EDIT
    const handleEditAssembly = (id: any) => {
        updateLoading(true)
        const assemblyToEdit = assembliesWithId?.find(assembly => assembly.id === id);
        if (assemblyToEdit) {
            const { id, ...assemblyWithoutId } = assemblyToEdit;
            formikAssembly.setValues(assemblyWithoutId);
            setAssemblyBeforeEdit(assemblyToEdit);
            handleDeleteAssembly(id);  // Remove the assembly temporarily from the list
            setEditMode(true);
        } else {
            console.log("Assembly not found with ID:", id);
        }
        updateLoading(false)
    };

    const handleEditAssemblySave = () => {
        updateLoading(true)
        const newAssembly = { ...formikAssembly.values, id: genUUID() };
        const updatedAssemblies = [...assembliesWithId || [], newAssembly]; // Adding back edited assembly

        setAssemblyWithId(updatedAssemblies) // Updating the assemblys with Ids state
        formik.setFieldValue("assembly", updatedAssemblies.map(({ id, ...rest }) => rest));  // Updating the main Formik state and removing ids

        formikAssembly.resetForm();  // Reseting the secondary Formik form
        setEditMode(false);
        updateLoading(false)
    };

    // Cancel
    const handleEditAssemblyCancel = () => {
        updateLoading(true)
        const oldAssembly = { ...assemblyBeforeEdit, id: genUUID() };
        const updatedAssemblies = [...assembliesWithId || [], oldAssembly]; // Restoring back the original assembly data 

        setAssemblyWithId(updatedAssemblies) // Updating the assemblys with Ids state
        formik.setFieldValue("assembly", updatedAssemblies.map(({ id, ...rest }) => rest)); // Updating the main Formik state and removing ids

        formikAssembly.resetForm(); // Reset the secondary Formik form
        setAssemblyBeforeEdit(undefined); // Reset the saved original assembly data
        setEditMode(false);
        updateLoading(false)
    };

    // Add
    const handleAddAssembly = () => {
        updateLoading(true)
        formikAssembly.validateForm().then(errors => {
            if (Object.keys(errors).length === 0 && formikAssembly.isValid) {  // Ensures there are no validation errors
                const newAssembly = { ...formikAssembly.values, id: genUUID() };
                const updatedAssemblies = [...assembliesWithId || [], newAssembly];

                setAssemblyWithId(updatedAssemblies) // Updating the assemblys with Ids state
                formik.setFieldValue("assembly", updatedAssemblies.map(({ id, ...rest }) => rest)); // Updating the main Formik state and removing ids

                formikAssembly.resetForm();  // Reset the secondary Formik form
            } else {
                // Making sure to touch all fields to show errors
                formikAssembly.setTouched({
                    description: true,
                    cost: true,
                    sequence: true,
                    itemNumber: true,
                    quantityRequired: true,
                    extendedPrice: true,
                });
            }
        });
        sumUpdate()
        updateLoading(false)
    };

    const sumUpdate = () => {
        const array = formik.values.assembly
        let sum = 0;
        console.log('sum', sum)

        return sumCost
    }

    useEffect(() => {
        // Because when tab switches, the state of this tab dies and when user comes back we need to put it in again
        const fetchData = async () => {
            const updatedAssemblyWithId = formik.values.assembly?.map(assembly => ({
                ...assembly,
                id: genUUID()
            }));

            if (updatedAssemblyWithId) {
                setAssemblyWithId(updatedAssemblyWithId);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Typography color="primary" display={"inline"}>{"ASSEMBLY"}</Typography>
            <span className="float-right font-medium">{components}</span>
            <span className="float-right pr-1 text-slate-500">{"COMPONENTS: "}</span>
            {/* <span className="text-slate-500">{"COST: "}</span>
            <span>{sumCost}</span> */}
            <Divider sx={{ marginBottom: 1 }} />
            {loading ? (
                <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={80} />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}>
                        <div className="grid w-full grid-flow-col grid-rows-6 gap-4 webkit-box">
                            <div className="flex justify-center">
                                <TextField variant="filled" {...textFieldProps} label={tAddPB("Sequence")} name="sequence" disabled={viewMode} type="number"
                                    error={Boolean(getFormikField(formikAssembly, "sequence").error)}
                                    helperText={getFormikField(formikAssembly, "sequence").error}
                                    value={getFormikField(formikAssembly, "sequence").value} onChange={formikAssembly.handleChange}
                                />
                            </div>
                            <div className="flex justify-center">
                                <TextField variant="filled" {...textFieldProps} label={tAddPB("Item No.")} name="itemNumber" disabled={viewMode} type="text"
                                    error={Boolean(getFormikField(formikAssembly, "itemNumber").error)}
                                    helperText={getFormikField(formikAssembly, "itemNumber").error}
                                    value={getFormikField(formikAssembly, "itemNumber").value} onChange={formikAssembly.handleChange}
                                />
                            </div>
                            <div className="flex justify-center">
                                <TextField fullWidth variant="filled" {...textFieldProps} label={tAddPB("Description")} name="description" disabled={viewMode}
                                    error={Boolean(getFormikField(formikAssembly, "description").error)}
                                    helperText={getFormikField(formikAssembly, "description").error}
                                    value={getFormikField(formikAssembly, "description").value} onChange={formikAssembly.handleChange}
                                />
                            </div>
                        </div>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <div className="grid w-full grid-flow-col grid-rows-6 gap-4 webkit-box">
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={tAddPB("Quantity Required")} name="quantityRequired" disabled={viewMode} type="number"
                                        error={Boolean(getFormikField(formikAssembly, "quantityRequired").error)}
                                        helperText={getFormikField(formikAssembly, "quantityRequired").error}
                                        value={getFormikField(formikAssembly, "quantityRequired").value} onChange={formikAssembly.handleChange}
                                    />
                                </div>
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={tAddPB("Unit Cost")} name="cost" disabled={viewMode} type="number"
                                        error={Boolean(getFormikField(formikAssembly, "cost").error)}
                                        helperText={getFormikField(formikAssembly, "cost").error}
                                        value={getFormikField(formikAssembly, "cost").value} onChange={formikAssembly.handleChange}
                                    >
                                    </TextField>
                                </div>
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={tAddPB("Extended Price")} name="extendedPrice" disabled={viewMode} type="number"
                                        error={Boolean(getFormikField(formikAssembly, "extendedPrice").error)}
                                        helperText={getFormikField(formikAssembly, "extendedPrice").error}
                                        value={getFormikField(formikAssembly, "extendedPrice").value} onChange={formikAssembly.handleChange}
                                    >
                                        <MenuItem value="US">US</MenuItem>
                                    </TextField>
                                </div>
                            </div>
                        </Box>
                        <Box sx={{ paddingX: 5 }}>
                            <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                {editMode ? (
                                    <>
                                        <Tooltip title={tGlobal("Save")}>
                                            <IconButton disabled={viewMode} size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditAssemblySave}>
                                                <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={tGlobal("Cancel")}>
                                            <IconButton disabled={viewMode} size="small" sx={{ p: 0 }} onClick={handleEditAssemblyCancel}>
                                                <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                            </IconButton>
                                        </Tooltip></>
                                ) : (
                                    <Tooltip title={tGlobal("Add Assembly")} >
                                        <IconButton disabled={viewMode}>
                                            <AddIcon onClick={handleAddAssembly} cursor={"pointer"} fontSize={"large"} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        </Box>
                    </Box >
                    <Divider sx={{ marginBottom: 1 }} />
                    <div style={{ height: 200, width: "100%" }}>
                        <DataGridPro
                            sx={{
                                border: 0, // Removes all borders
                                "& .MuiDataGrid-cell": {
                                    padding: columnPadding, // Adjust the padding as needed
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    padding: columnPadding, // Adjust the padding as needed
                                }
                            }}
                            hideFooter
                            disableColumnSorting
                            disableColumnFilter
                            rows={assembliesWithId}
                            columns={columns}
                            pageSizeOptions={[assembliesWithId?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                        />
                    </div>
                </>
            )}
        </>
    );
}

