import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import ApiClient from "../../util/api-client"
import type { Order, OrderCreateInput, OrderDeleteInput, OrderItem } from "orderflow-lambdas"
import dayjs from "dayjs"

export enum OrderModalMode {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit',
}

export interface OrderModalState {
  stateIsChanged: boolean
  isOpenOrderModal: boolean
  orderIdToEdit?: string
  deleteOrderModal: boolean
  orderToDelete: Order | undefined
  mode: OrderModalMode;
  loading: boolean
  updateCallback?: (newOrder: Order | undefined) => Promise<void>
  createCallback?: (newOrder: Order | undefined) => Promise<void>
  deleteCallback?: (newOrder: Order | undefined) => Promise<void>
  formData: OrderCreateInput
}

const initialFormData: OrderCreateInput = {
  code: '',
  rate: 0,
  orderDate: 0,
  estimatedShipDate: 0,
  customerDeposit: 0,
  shipMethod: '',
  deadline: 0,
  paymentTerms: [],
  customer: {
    code: '',
    companyName: '',
    CustomerId: ''
  },
  items: [],
  specialInstructions: ""
}

// Initialize state
const initialState: OrderModalState = {
  stateIsChanged: false,
  isOpenOrderModal: false,
  orderIdToEdit: undefined,
  deleteOrderModal: false,
  orderToDelete: undefined,
  mode: OrderModalMode.CREATE,
  loading: false,
  formData: initialFormData,
}

export const orderModalSlice = createSlice({
  name: "orderModal",
  initialState,
  reducers: {
    openOrderModal: (state, action: PayloadAction<{
      createCallback?: (newOrder: Order | undefined) => Promise<void>,
      resetState?: boolean
    } | undefined>) => {
      // If resetState is true, reset the formData and any other relevant fields
      if (action.payload?.resetState) {
        return {
          ...initialState,
          isOpenOrderModal: true,
          createCallback: action.payload.createCallback,
          formData: {
            ...initialFormData,
            orderDate: dayjs().unix()
          } // Reset form data back to initial
        };
      }

      // Otherwise, open the modal without resetting
      return {
        ...state,
        isOpenOrderModal: true,
        createCallback: action.payload?.createCallback,
      };
    },
    openEditOrderModal: (state, action: PayloadAction<{
      orderIdToEdit: string,
      mode?: OrderModalMode,
      updateCallback?: (user: Order | undefined) => Promise<void>,
    }>) => {
      return {
        ...state,
        orderIdToEdit: action.payload.orderIdToEdit,
        isOpenOrderModal: true,
        updateCallback: action.payload?.updateCallback,
        isEditingOrder: true,
        mode: action.payload?.mode || OrderModalMode.EDIT
      }
    },
    setFormData: (state, action: PayloadAction<OrderCreateInput>) => {
      // Update form data in redux whenever form changes
      return {
        ...state,
        formData: action.payload,
        stateIsChanged: true
      }
    },
    addItemsToOrder: (state, action: PayloadAction<OrderItem[]>) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          items: [...state.formData.items, ...action.payload],
        },
        stateIsChanged: true
      }
    },
    setMode: (state, action: PayloadAction<OrderModalMode>) => ({
      ...state,
      mode: action.payload
    }),
    closeModal: (state, action: PayloadAction<{ resetState?: boolean } | undefined>) => {
      if (action.payload?.resetState) return { ...initialState };
      return {
        ...state,
        isOpenOrderModal: false,
        deleteOrderModal: false,
      };
    },
    openDeleteOrderModal: (state, action: PayloadAction<{
      orderToDelete: Order,
      deleteCallback?: (user: Order | undefined) => Promise<void>,
    }>) => {
      return {
        ...state,
        deleteOrderModal: true,
        orderToDelete: action.payload.orderToDelete,
        deleteCallback: action.payload.deleteCallback,
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
})

export const {
  openOrderModal,
  openEditOrderModal,
  addItemsToOrder,
  closeModal,
  openDeleteOrderModal,
  setMode,
  setLoading,
  setFormData
} = orderModalSlice.actions


// Thunks
export const fetchOrderToEdit = (id: string) => async (dispatch: Dispatch): Promise<Order> => {
  try {
    dispatch(setLoading(true))
    const order: Order = await ApiClient.orderGetById.query(id);
    return order
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteOrder = (deleteInput: OrderDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteOrder.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}

export default orderModalSlice.reducer;
